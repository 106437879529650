import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableHead,
  CircularProgress,
  Card,
  Tabs,
  Tab,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import {
  _batch_expected_revenue,
  _list_student_assignments,
  _student_assignments_attendance_and_fee_voucher_along_batch_id,
  listActiveBatch,
} from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { formatDateTimeWithOutZone, formatDateTimeWithZone } from "src/utils";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { useAppContext } from "src/hooks";
import {
  privilegesNameCheckAndUpperCase,
  privilegesTypeCheckAndShowOrNot,
} from "src/utils/FormatPrivileges";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  student_icon_1,
  student_icon_2,
  teacher_icon_1,
  teacher_icon_2,
} from "src/assets";
import CircularProgressWithLabel from "src/components/loaders/CircularProgressWithLabel";
import {
  fDate,
  formatDateWithDay,
  formatDateWithOutDay,
  formatTime24Hours,
} from "src/utils/formatTime";
import CustomModal from "src/components/GeneralComponents/CustomModal";
// import BatchDetailTabInfo from "./components/BatchDetailTabInfo";
import CustomTabPanel from "src/components/GeneralComponents/CustomTabPanel";
import { _student_dashboard } from "src/DAL/dashboard";
import BatchDetailAttendanceList from "../Batches/Detail/components/BatchDetailAttendanceList";
import AssignmentsDetailList from "./components/AssignmentsDetailList";
// import BatchDetailVouchersList from "./components/BatchDetailVouchersList";
// import BatchDetailAttendanceList from "./components/BatchDetailAttendanceList";

const AssignmentsList = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [currency, setCurrency] = useState("Rs");

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  const [assignments, setAssignments] = useState([]);
  const [assignmentsSubmitted, setAssignmentsSubmitted] = useState([]);
  const [assignmentsApproved, setAssignmentsApproved] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [pendingVouchers, setPendingVouchers] = useState([]);
  const [paidVoucher, setPaidVoucher] = useState([]);
  const [clearanceVouchers, setClearanceVouchers] = useState([]);
  const [batchInfo, setBatchInfo] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [percentage, setPercentage] = useState(0);

  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRowData) {
    setOpenModal(true);
    // setSelectedRow(selectedRowData);
  }

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };
  const myStyleHeader2 = {
    // textTransform: "capitalize",
    fontWeight: 800,
    fontSize: "1.5rem", // Adjust the font size as needed
    margin: "1rem 0", // Add margin for spacing, adjust as needed
  };

  const myStyleHeader3 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    textAlign: "center",
  };
  const myStyle3 = {
    textTransform: "capitalize",
    textAlign: "center",
  };

  const myStyle4 = {
    textTransform: "capitalize",
    textAlign: "center",
    fontWeight: 600,
  };
  const myStyle5 = {
    textTransform: "capitalize",
    fontWeight: 600,
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    status: "",
    education: "",
    photo: "",
    batches: [],
    batch_fee: [],
    student_vouchers: [],
    dob: "",
    course_fee: "",
    course: {
      _id: "",
      title: "",
    },
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",
    discount: 0,

    registration_fee_discount: 0,
    fee_discount: 0,
    registration_fee_discount_reason: "",
    fee_discount_reason: "",

    fee_status: "all_paid",

    instructors: [],
    batch_fee: [],

    batch_total_paid_fee_revenue: 0,
    collected_registration_revenue: 0,
    total_monthly_fee_discount: 0,

    pending_registration_revenue: 0,
    batch_total_pending_fee_revenue: 0,

    expected_registration_revenue_without_discount: 0,
    batch_expected_registration_revenue_after_discount: 0,
    total_registration_discount: 0,

    total_registration_additional_discount: 0,

    paid_registration_revenue: 0,
    pending_registration_revenue: 0,
    expected_monthly_fee_revenue_without_discount: 0,
    expected_monthly_fee_revenue_after_discounts: 0,
    total_monthly_fee_discount: 0,
    generated_voucher_revenue: 0,

    generated_fee_voucher_additional_discount: 0,
    generated_fee_voucher_fee_discount: 0,

    batch_total_paid_fee_revenue: 0,
    batch_total_pending_fee_revenue: 0,

    predicted_revenue: [],
    collected_revenue: [],

    total_paid_registration_fee: 0,
    total_registration_fee_after_discount: 0,
    total_registration_fee_before_discount: 0,
    total_registration_voucher_additional_discount: 0,
    total_registration_voucher_discount: 0,

    // "expected_registration_revenue_without_discount": 100,
    // "batch_expected_registration_revenue_after_discount": 91,
    // "total_registration_discount": 9,

    // "total_registration_additional_discount": 0,

    // "paid_registration_revenue": 0,
    // "pending_registration_revenue": 91,
    // "expected_monthly_fee_revenue_without_discount": 40,
    // "expected_monthly_fee_revenue_after_discounts": 36,
    // "generated_voucher_revenue": 16,

    // "generated_fee_voucher_additional_discount": 2,
    // "generated_fee_voucher_fee_discount": 2,

    // "batch_total_paid_fee_revenue": 0,
    // "batch_total_pending_fee_revenue": 16,
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  const fetchVoucherList = async () => {
    setIsLoading(true);
    const response = await _student_dashboard();

    console.log("response _student_dashboard", response);

    // setCourses(data.batch.courses);
    if (response.code === 200) {
      if (response.assignments.length > 0) {
        const Pending = response.assignments.filter(
          (item) => item.student.assignment_status === 1
        );
        const Submitted = response.assignments.filter(
          (item) => item.student.assignment_status ===2 
        );
        const Approved = response.assignments.filter(
          (item) => item.student.assignment_status ===3 
        );
        setAssignments(Pending);
        setAssignmentsSubmitted(Submitted);
        setAssignmentsApproved(Approved);
      } else {
        // const Pending = response.assignments.filter(
        //     (item) => item.submitted_by.length === 0
        //   );
        //   const Submitted = response.assignments.filter(
        //     (item) => item.submitted_by.length > 0
        //   );

        setAssignments([]);
        setAssignmentsSubmitted([]);
        setAssignmentsApproved([]);
      }

      //   setVouchers(response.fee_vouchers);
      //   setPendingVouchers(Pending);
      //   setPaidVoucher(Paid);
      //   setClearanceVouchers(Clearance);

      //   setBatchInfo(response.batch_info);
      //   setInstructors(response.batch_info.batch_instructors);

      setIsLoading(false);
      //   setPercentage(response.performance_percentage);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // fetchDetailBatch();
    fetchVoucherList();
  }, []);

  //   if (isLoading) {
  //     return (
  //       <div className="mt-5">
  //         <Box sx={{ width: "100%" }}>
  //           <LinearProgress />
  //         </Box>
  //       </div>
  //     );
  //   }
  //   const centerStyle = {
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     // height: '100vh', // Optional to center vertically in the viewport
  //   };

  if (isLoading) {
    return <CircularLoader size="3rem" color="secondary" />;
  }

  return (
    <Page title="Assignments">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px", marginBottom: "15px" }}
      >
        {/* container start */}
        <div className="container">
          <div
            className="d-flex"
            style={{
              flexDirection: "row",
              alignContent: "flex-end",
              justifyContent: "space-between",
              //   marginBottom: "-15px",
            }}
          >
            {/* <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton> */}

            {/* <Typography variant="h4">Vouchers</Typography> */}
            <h4>Assignments</h4>

            <div></div>
          </div>

          {/* <hr /> */}

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Pending" {...a11yProps(0)} />
                <Tab label="Submitted" {...a11yProps(1)} />
                <Tab label="Approved" {...a11yProps(3)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
              <AssignmentsDetailList assignments={assignments} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <AssignmentsDetailList assignments={assignmentsSubmitted} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <AssignmentsDetailList assignments={assignmentsApproved} />
            </CustomTabPanel>
          </Box>
        </div>
      </Grid>

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="All Instructors"
        // StyleFromHeader={ModalStyle}
        componentToPassDown={
          <>
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                paddingTop: 5,
              }}
            >
              {instructors.map((instructor, index) => {
                return (
                  <>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt={instructor.name}
                          src={s3baseUrl + instructor.image}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary="&nbsp;"
                        secondary={`${capitalizeFirstLetter(
                          instructor.first_name
                        )} ${capitalizeFirstLetter(instructor.last_name)}`}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                );
              })}
            </List>
          </>
        }
      />
    </Page>
  );
};

export default AssignmentsList;
