// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "Batches",
    path: "/batches",
    icon: getIcon("ph:student-bold"),
  },
  {
    title: "Assignments",
    path: "/assignments",
    icon: getIcon("material-symbols-light:menu-book"),
  },
  {
    title: "Attendance",
    path: "/attendance",
    icon: getIcon("material-symbols:assignment-outline"),
  },
  {
    title: "Vouchers",
    path: "/vouchers",
    icon: getIcon("tabler:file-dollar"),
  },
  {
    title: "Leave Requests",
    path: "/leave-requests",
    icon: getIcon("pepicons-pop:leave"),
  },
  {
    title: "Announcements",
    path: "/announcements",
    icon: getIcon("mdi:announcement-outline"),
  },
  {
    title: "Support Tickets",
    path: "/support-tickets",
    icon: getIcon("material-symbols:support-agent"),
  },
];

export default navConfig;
