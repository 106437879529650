import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// components
import Iconify from "../../components/Iconify";
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import { _list_user_notifications } from "src/DAL/notifications";
import { useEffect, useState } from "react";
import { getTimeDifference } from "src/utils/formatTime";
import { sub } from "date-fns";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_DESKTOP = "8vh";
const APPBAR_MOBILE = "8vh";

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  // backdropFilter: "blur(6px)",
  // WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  // backgroundColor: "#f7f7fa",
  // backgroundColor: "#f7f7fa",

  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({
  onOpenSidebar,
  isLoading,
  setIsLoading,
  notifications, 
  setNotifications,
  
  countNotifications, 
  setCountNotifications,
}) {

  // const getUserNotifications = async () => {
  //   setIsLoading(false);
  //   const response = await _list_user_notifications();
  //   console.log(
  //     "response _test _list_user_notifications",
  //     response,
  //     new Date("2024-02-29T11:17:08.189Z")
  //   );

  //   if (response.code === 200) {
  //     // getTimeDifference

  //     const allNotifications = response.notifications.map(
  //       (notification, index) => ({
  //         ...notification,

  //         id: notification._id,
  //         title: notification.title,
  //         description: notification.message,
  //         avatar: null,
  //         type: notification.notification_type,
  //         // createdAt: getTimeDifference(notification.createdAt),
  //         // createdAt: sub(new Date(), new Date(notification.createdAt)),
  //         // createdAt : sub(new Date(), new Date('2024-02-29T11:17:08.189Z')),
  //         createdAt: sub(new Date(notification.createdAt), new Date()),

  //         isUnRead: !notification.read_status,
  //       })
  //     );
  //     setNotifications(allNotifications);
  //     console.log("allNotifications  ____notifications", allNotifications);
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(response.message, { variant: "error" });
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    // getUserNotifications();
  }, []);

  return (
    <RootStyle id="mui-root-style">
      <ToolbarStyle className="hide_when_print">
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar />  */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <NotificationsPopover
            notifications={notifications}
            setNotifications={setNotifications}
            
            countNotifications={countNotifications}
            setCountNotifications={setCountNotifications}
          />
          <AccountPopover isLoading={isLoading} setIsLoading={setIsLoading} />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
