import React from "react";

import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
// material
import {
  Avatar,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
  Badge,
  Icon,
  FormHelperText,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Paper,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";

import { _student_detail_with_token, detailAdmin } from "src/DAL/auth";
import { s3baseUrl } from "src/config/config";
import {
  _detail_customer_admin,
  _detail_customer_without_token,
  _instructor_detail_with_token,
} from "src/DAL/customer";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { default_avatar_1 } from "src/assets";
import CustomInputPaperView from "src/components/GeneralComponents/CustomInputPaperView";
import CustomInputPaperViewFullLength from "src/components/GeneralComponents/CustomInputPaperViewFullLength";
import { isValidDate } from "src/utils/formatTime";
const Profile = () => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = () => {
    // You can perform any necessary actions here before navigating
    // For example, submitting data, handling loading, etc.
    // After that, navigate to the desired route.
    navigate("/profile/edit-profile");
  };

  const Input = styled("input")({
    display: "none",
  });

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    profile_image: "",
    last_name: "",
    name: "",
    father_name: "",
    email: "",
    address: "",
    date_of_birth: "",
    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    cnic: "",
    identity_card_number: "",
  });

  const fileChangedHandler = (event) => {
    setImage(event.target.files[0]);
    setPreviews(URL.createObjectURL(event.target.files[0]));
  };

  const fetchProfile = async () => {
    // Check if "user" is in localStorage and parse it
    const storedObjectString = localStorage.getItem("user");
    if (storedObjectString) {
      const storedUser = JSON.parse(storedObjectString);
      console.log("storedUser  __storedUser", storedUser);

      if (storedUser.type === 0) {
        // Type 0: Make API call using detailAdmin()
        const response = await detailAdmin();
        console.log("response ___response", response);
        if (response.code === 200) {
          setIsLoading(false);
          setFormInputs({
            ...response.admin,
            first_name: capitalizeFirstLetter(response.admin.first_name),
            last_name: capitalizeFirstLetter(response.admin.last_name),
            profile_image: response.admin.profile_image,
            _id: response.admin.user_id._id,
          });
        } else {
          setIsLoading(false);
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } else if (storedUser.type === 3) {
        // Type 3: Make API call using detailAdmin()
        const response = await _detail_customer_without_token();
        console.log("response _detail_customer_without_token", response);
        if (response.code === 200) {
          setIsLoading(false);

          setFormInputs({
            ...response.customer,
            first_name: capitalizeFirstLetter(response.customer.first_name),
            last_name: capitalizeFirstLetter(response.customer.last_name),
            profile_image: response.customer.profile_image,
            _id: response.customer.user_id._id,
            // contact_number: response.customer.contact_number,
            // post_code: response.customer.post_code,
            // role_id: response.customer.role._id,
          });
        } else {
          setIsLoading(false);
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } else if (storedUser.type === 2) {
        // Type 3: Make API call using detailAdmin()
        const response = await _instructor_detail_with_token();
        console.log("response _instructor_detail_with_token", response);
        if (response.code === 200) {
          setIsLoading(false);

          setFormInputs({
            ...response.instructor,
            first_name: capitalizeFirstLetter(response.instructor.first_name),
            last_name: capitalizeFirstLetter(response.instructor.last_name),
            profile_image: response.instructor.image,
            _id: response.instructor.user_id,
            // contact_number: response.customer.contact_number,
            // post_code: response.customer.post_code,
            // role_id: response.customer.role._id,
          });
        } else {
          setIsLoading(false);
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } else {
        setIsLoading(false);
      }
    }
  };

  const fetchProfile2 = async () => {
    // Check if "user" is in localStorage and parse it
    // const storedObjectString = localStorage.getItem("user");
    // if (storedObjectString) {
    // const storedUser = JSON.parse(storedObjectString);

    const response = await _student_detail_with_token();
    console.log("response _student_detail_with_token", response);
    if (response.code === 200) {
      // setIsLoading(false);
      // setAdmin(response.student);
      setFormInputs({
        ...response.student,
        first_name: capitalizeFirstLetter(response.student.name),
        last_name: capitalizeFirstLetter(response.student.father_name),
        profile_image: response.student.image,
        _id: response.student._id,
        // contact_number: response.customer.contact_number,
        // post_code: response.customer.post_code,
        // role_id: response.customer.role._id,
      });

      if (response.student.image) {
        setImage(response.student.image);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile2();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Profile">
      <div className="container">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // marginTop: "10px",
            // marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ marginBottom: "5px", flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          {/* <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Profile
          </Typography> */}
        </Grid>

        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            mt: 1,
            width: "100%",
            p: 4,
          }}
        >
          <form
            autoComplete="off"
            // onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row table-form">
              <div className="col-12 mt-4 mb-4">
                <div className="row">
                  <div className="col-12 text-center  ">
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        name="image"
                        onChange={fileChangedHandler}
                        disabled
                      />

                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <>
                            {/* <span
                            style={{
                              border: "1px solid #dbdbdb",
                              borderRadius: "50%",
                              background: "white",
                              display: "inline-block",
                              padding: "5px",
                              color: "#00ab55",
                            }}
                          >
                            <Icon
                              style={{
                                height: "40px",
                                width: "40px",
                                padding: "5px",
                              }}
                              icon="fluent:camera-add-20-filled"
                              width={20}
                              height={20}
                            />
                          </span> */}
                          </>
                        }
                      >
                        <Avatar
                          id="contained-button-file"
                          src={
                            // previews || default_avatar_1
                            `${s3baseUrl}${image}` || default_avatar_1
                            // `${s3baseUrl}uploads/assets/profile-icon.png`
                          }
                          className="avatar"
                          alt={formInputs.name}
                          style={{
                            width: "150px",
                            height: "150px",
                            border: "1px solid #dbdbdb",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            fontSize: "30px", // Adjust the font size as desired
                          }}
                        />
                      </Badge>
                    </label>
                    {/* <FormHelperText className="pt-3 text-center">
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText> */}
                  </div>
                </div>
              </div>
              {/* Here form input code */}

              <Grid container spacing={1} sx={{ marginLeft: "-4px" }}>
                <CustomInputPaperView
                  Label="Name"
                  Name={formInputs.name}
                  IconSet="ri:user-fill"
                  marginTopSet="20px"
                />
                <CustomInputPaperView
                  Label="Father Name"
                  Name={formInputs.father_name}
                  IconSet="subway:admin"
                  marginTopSet="20px"
                />
                <CustomInputPaperView
                  Label="Email"
                  Name={formInputs.email}
                  IconSet="line-md:email"
                  marginTopSet="20px"
                />

                <CustomInputPaperView
                  Label="Phone"
                  Name={`+92${formInputs.contact_number}`}
                  IconSet="ph:phone-fill"
                  marginTopSet="20px"
                />

                <CustomInputPaperView
                  Label="Latest Degree"
                  Name={`${capitalizeFirstLetter(formInputs.latest_degree)}`}
                  IconSet="icon-park-solid:degree-hat"
                  marginTopSet="20px"
                />
                <CustomInputPaperView
                  Label="Degree Status"
                  Name={`${capitalizeFirstLetter(formInputs.degree_status)}`}
                  IconSet="healthicons:i-certificate-paper-outline"
                  marginTopSet="20px"
                />

                {formInputs.degree_status === "complete" ? (
                  <CustomInputPaperView
                    Label="Completion Year"
                    Name={`${formInputs.completion_year}`}
                    IconSet="iwwa:year"
                    marginTopSet="20px"
                  />
                ) : (
                  ""
                )}

                <CustomInputPaperView
                  Label="Institution Name"
                  Name={`${capitalizeFirstLetter(formInputs.institution_name)}`}
                  IconSet="vaadin:institution"
                  marginTopSet="20px"
                />

                {formInputs.degree_status === "inprogress" ? (
                  <CustomInputPaperView
                    Label="Semester"
                    Name={`${formInputs.semester}`}
                    IconSet="tdesign:course"
                    marginTopSet="20px"
                  />
                ) : (
                  ""
                )}

                <CustomInputPaperView
                  Label="CNIC"
                  Name={`${formInputs.cnic}`}
                  IconSet="game-icons:id-card"
                  marginTopSet="20px"
                />

                {isValidDate(formInputs.date_of_birth) !== null ? (
                  <CustomInputPaperView
                    Label="Date Of Birth"
                    Name={`${isValidDate(formInputs.date_of_birth)}`}
                    IconSet="arcticons:birthdays"
                    marginTopSet="20px"
                  />
                ) : (
                  <CustomInputPaperView
                    Label="Date Of Birth"
                    Name={``}
                    IconSet="arcticons:birthdays"
                    marginTopSet="20px"
                  />
                )}

                <CustomInputPaperViewFullLength
                  Label="Address"
                  Name={formInputs.address}
                  IconSet="mdi:address-marker-outline"
                  marginTopSet="20px"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "30px",
                }}
              >
                {/* <LoadingButton
                    size="medium"
                    variant="contained"
                    component={RouterLink}
                    to="/profile/edit-profile"
                    sx={{ width: "120px" }}
                  >
                    Edit Profile
                  </LoadingButton>  */}

                <LoadingButton
                  size="medium"
                  variant="contained"
                  sx={{ width: "120px" }}
                  onClick={handleClick}
                >
                  Edit Profile
                </LoadingButton>
              </Grid>
            </div>
          </form>
        </Card>
      </div>
    </Page>
  );
};

export default Profile;
