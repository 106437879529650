import { invokeApi } from "src/utils";


export const _student_batches = async () => {
  const requestObj = {
    path: `api/student/student_batches/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};


export const _student_assignments_attendance_and_fee_voucher_along_batch_id = async (id) => {
  const requestObj = {
    path: `api/student/student_assignments_attendance_and_fee_voucher_along_batch_id/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};


export const _list_student_assignments = async (data) => {
  const requestObj = {
    path: `api/assignment/list_student_assignments`, 
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const listBatch = async () => {
  const requestObj = {
    path: `api/batch/list_batch`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _batch_students = async (data) => {
  const requestObj = {
    path: `api/batch/batch_students`, 
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_confirm_and_started_batch = async () => {
  const requestObj = {
    path: `api/batch/list_confirm_and_started_batch`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const listActiveBatch = async () => {
  const requestObj = {
    path: `api/batch/active_batch_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const delBatch = async (id) => {
  const requestObj = {
    path: `api/batch/delete_batch/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const _list_freeze_batches = async (data) => {
  const requestObj = {
    path: `api/batch/list_freeze_batches`, 
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addBatch = async (data) => {
  const requestObj = {
    path: `api/batch/add_batch`, 
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _batch_expected_revenue = async (id) => {
  const requestObj = {
    path: `api/batch/batch_expected_revenue/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const detailBatch = async (id) => {
  const requestObj = {
    path: `api/batch/detail_batch/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _update_freeze_status = async (id) => {
  const requestObj = {
    path: `api/batch/update_freeze_status/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editBatch = async (id, data) => {
  const requestObj = {
    path: `api/batch/edit_batch/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};


export const _filter_batch = async (data) => {

  const requestObj = {
    path: `api/batch/batch_filter`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _move_batch_students = async (data) => {
  const requestObj = {
    path: `api/batch/move_batch_students`, 
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _move_student_from_batch = async (data) => {
  const requestObj = {
    path: `api/batch/move_student_from_batch`, 
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_batch_status = async (id, data) => {
  const requestObj = {
    path: `api/batch/update_batch_status/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_student_status_from_batch = async ( data) => {
  const requestObj = {
    path: `api/batch/update_student_status_from_batch/`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};