import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import { Card, Typography, Grid, Badge, Chip, Button } from "@mui/material";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import {
  compareDateWithTodayDate,
  formatDateWithDay,
} from "src/utils/formatTime";
import { s3baseUrl } from "src/config/config";
import Label from "../Label";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(0, 0),
  borderRadius: "15px",
  padding: "5px 2px",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  marginTop: "0px",
  display: "flex",
  borderRadius: "25%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
//   backgroundColor: "#FFFFFF", 
  backgroundColor: "#f2f3f5",
  marginTop: "-3px",
}));

export default function CustomCardAssignmentFullLength({
  voucher,
  Fee,
  Title,
  className,
  Icon,
  voucherStatus,
  dueDate,
  handleCardClick,
  // handleDownloadClick,
}) {
  const [invoiceLink, setInvoiceLink] = useState(
    `${s3baseUrl}${voucher.student.assignment_url}`
  );

  const handleDownloadClick = () => {
    // Use the "click" method to trigger the download
    const link = document.createElement("a");
    link.href = invoiceLink;
    link.download = "downloaded-file"; // Set the desired file name
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Recommended for security reasons when using target="_blank"
    link.click();
  };

  const handleBadgeClick = (event) => {
    // Stop the propagation of the click event to prevent it from reaching the parent
    event.stopPropagation();
    handleDownloadClick();
  };

  console.log("voucher ____voucher", voucher);
  return (
    <Grid item xs={12} sm={12} md={12}>
      <RootStyle className={className}>
        <Grid
          container
          sx={{ pt: 0 }}
          // onClick={(event) => handleCardClick(event, voucher)}
          onClick={(event) => {
            console.log("Card clicked:");
            handleCardClick(voucher);
          }}
        >
          <Grid item xs={6} sm={6} md={3} sx={{ pl: 1 }}></Grid>
          <Grid item xs={6} sm={6} md={3} sx={{ color: "black" }}></Grid>
          <Grid item xs={6} sm={6} md={3}  sx={{ 
    color: "black", 
    display: "flex", 
    justifyContent: "center", // Center horizontally
    alignItems: "center",     // Center vertically
    paddingLeft:"10px",
  }}>
            {/* {voucher.student.result_status   ? (
              <Label
                variant="outlined"
                color={
                  voucher.student.result_status === 3 ? "error" : 
                  voucher.student.result_status === 2 ? "success" : 
                  "warning"}
              >
                {`${
                  voucher.student.result_status === 3 ? "Failed" :
                  voucher.student.result_status === 2 ? "Pass" :
                  "Pending"
                }`}
              </Label>
            ) : (
              ""
            )} */}
          </Grid>
          <Grid item xs={6} sm={6} md={3} sx={{ color: "black" }}>
            <Chip
              label={formatDateWithDay(dueDate)}
              color={
                voucherStatus == 2 || voucherStatus == 3
                  ? "success"
                  : compareDateWithTodayDate(dueDate) == 1
                  ? "warning"
                  : compareDateWithTodayDate(dueDate) == 0
                  ? "error"
                  : "info"
              }
              sx={{
                color:
                  voucherStatus == 2 || voucherStatus == 3 ? "white" : "",
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3} sx={{ pl: 1, mt: 1 }}>
            <Badge
              //  onClick={handleBadgeClick}
              badgeContent={
                voucherStatus == 1
                  ? "Pending"
                  : voucherStatus == 2
                  ? "Submitted"
                  : voucherStatus == 3
                  ? "Approved"
                  : "Pending"
              }
              color={
                voucherStatus == 1
                  ? "warning"
                  : voucherStatus == 2
                  ? "success"
                  : "primary"
              }
            >
              <IconWrapperStyle>{Icon}</IconWrapperStyle>
            </Badge>
          </Grid>
          <Grid item xs={6} sm={6} md={3} sx={{ color: "black", pt: 1 }}>
          <div className="views-lesson">
                <div className="batch_info_heading">Title</div>
                <div className="batch_info_sub_text">
                  {capitalizeFirstLetter(Title)}
                </div>
              </div>

            {/* <Typography variant="subtitle2" sx={{ opacity: 1.0 }}>
              {capitalizeFirstLetter(Title)}
            </Typography> */}
          </Grid>
          <Grid item xs={6} sm={6} md={3} sx={{ color: "black", pt: 1  }}>
          <div className="views-lesson">
                <div className="batch_info_heading">Batch</div>
                <div className="batch_info_sub_text">
                  {capitalizeFirstLetter(voucher.batch.name)}
                </div>
              </div>

            {/* <Label variant="outlined" color={"info"}>
              {`Batch : ${capitalizeFirstLetter(voucher.batch.name)}`}
            </Label> */}
          </Grid>

          <Grid item xs={6} sm={6} md={3} sx={{ color: "black", pt: 1  }}>
            {/* <Typography variant="h6" sx={{ opacity: 1.0 }}>
              {voucher.student.obtained_marks !== null
                ? `${voucher.student.obtained_marks}/${Fee} Marks`
                : `${Fee} Total Marks`}
            </Typography> */}

            <div className="views-lesson">
                <div className="batch_info_heading">{voucher.student.obtained_marks !== null
                ? `${voucher.student.obtained_marks}/${Fee} Marks`
                : `${Fee} Total Marks`}</div>
                <div className="batch_info_sub_text mt-1">

                {voucher.student.result_status=== 3 || voucher.student.result_status===2   ? (
              <Label
                variant="outlined"
                color={
                  voucher.student.result_status === 3 && voucherStatus ===3 ? "error" : 
                  voucher.student.result_status === 2 && voucherStatus ===3 ? "success" : 
                  "warning"}
              >
                {`${
                  voucher.student.result_status === 3 && voucherStatus ===3 ? "Failed" :
                  voucher.student.result_status === 2 && voucherStatus ===3 ? "Pass" :
                  "Pending"
                }`}
              </Label>
            ) : (
              ""
            )}
            <span style={{fontWeight:500}}> 
              {voucher.student.obtained_marks !== null && voucherStatus ===3
                ? `    ${voucher.student.percentage}% `
                : ``}
                </span>
                </div>
              </div>

          </Grid>
        </Grid>
      </RootStyle>
    </Grid>
  );
}
