import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//pages
import {
  Dashboard,
  EditProfile,
  ForgotPassword,
  Login,
  Page404,
  Profile,
  Register,
} from "./pages";
import ChangePassword from "./pages/Profile/ChangePassword/ChangePassword";
import BatchesList from "./pages/Batches/List/BatchesList";
import BatchesDetail from "./pages/Batches/Detail/BatchesDetail";
import VoucherList from "./pages/Vouchers/VoucherList";
import AttendanceList from "./pages/Attendance/AttendanceList";
import AssignmentsList from "./pages/Assignments/AssignmentsList";
import AssignmentSubmit from "./pages/Assignments/AssignmentSubmit/AssignmentSubmit";
import LeaveRequestList from "./pages/LeaveRequest/List/LeaveRequestList";
import AddLeaveRequest from "./pages/LeaveRequest/Add/AddLeaveRequest";
import EditLeaveRequest from "./pages/LeaveRequest/Edit/EditLeaveRequest";
import LoginAdmin from "./pages/login/LoginAdmin";
import SupportTicketsList from "./pages/SupportTickets/List/SupportTicketsList";
import AddSupportTicket from "./pages/SupportTickets/Add/AddSupportTicket";
import EditSupportTicket from "./pages/SupportTickets/Edit/EditSupportTicket";
import AnnouncementList from "./pages/Announcements/List/AnnouncementList";
import AddAnnouncement from "./pages/Announcements/Add/AddAnnouncement";
import EditAnnouncement from "./pages/Announcements/Edit/EditAnnouncement";
import { useState } from "react";

// ----------------------------------------------------------------------

export default function Router() {
  const [announcementCount, setAnnouncementCount] = useState(0);
  const [supportCount, setSupportCount] = useState(0);
  return useRoutes([
    {
      path: "/",
      element: (
        <DashboardLayout
          supportCount={supportCount}
          setSupportCount={setSupportCount}
          announcementCount={announcementCount}
          setAnnouncementCount={setAnnouncementCount}
        />
      ),
      children: [
        { path: "dashboard", element: <Dashboard /> },

        { path: "batches", element: <BatchesList /> },
        {
          path: "batches/batch-detail/:id",
          element: <BatchesDetail />,
        },

        { path: "attendance", element: <AttendanceList /> },

        { path: "assignments", element: <AssignmentsList /> },
        {
          path: "assignments/assignment-submit/:id",
          element: <AssignmentSubmit />,
        },

        { path: "vouchers", element: <VoucherList /> },
        // {
        //   path: "vouchers/voucher-detail/:id",
        //   element: <BatchesDetail />,
        // },

        ///////////////// Leave Request
        { path: "leave-requests", element: <LeaveRequestList /> },
        {
          path: "leave-requests/add-leave",
          element: <AddLeaveRequest />,
        },
        {
          path: "leave-requests/edit-leave/:id",
          element: <EditLeaveRequest />,
        },

        ///////////////// Announcement
        {
          path: "announcements",
          element: (
            <AnnouncementList
              announcementCount={announcementCount}
              setAnnouncementCount={setAnnouncementCount}
            />
          ),
        },
        {
          path: "announcements/add-announcement",
          element: <AddAnnouncement />,
        },
        {
          path: "announcements/edit-announcement/:id",
          element: <EditAnnouncement />,
        },

        /////////////// SupportTickets
        { path: "support-tickets", element: <SupportTicketsList /> },
        {
          path: "support-tickets/add-ticket",
          element: <AddSupportTicket />,
        },
        {
          path: "support-tickets/edit-ticket/:id",
          element: (
            <EditSupportTicket
              supportCount={supportCount}
              setSupportCount={setSupportCount}
            />
          ),
        },

        /////////// Profile Change Password
        { path: "profile", element: <Profile /> },
        { path: "profile/edit-profile", element: <EditProfile /> },
        { path: "change-password", element: <ChangePassword /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "login", element: <Login /> },
        { path: "/login-admin/:id", element: <LoginAdmin /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
