import { invokeApi } from "src/utils";

export const login = async (data) => {
  console.log(data);
  const requestObj = {
    path: `api/app_api/login`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: JSON.stringify(data),
  };

  return invokeApi(requestObj);
};
export const _login_by_contact_number = async (data) => {
  console.log(data);
  const requestObj = {
    path: `api/app_api/login_by_contact_number`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: JSON.stringify(data),
  };

  return invokeApi(requestObj);
};

export const emailVerification = async (data) => {
  console.log(data);
  const requestObj = {
    path: `api/app_api/email_verification`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: JSON.stringify(data),
  };

  return invokeApi(requestObj);
};
export const detailAdmin = async () => {
  const requestObj = {
    path: `api/admin/detail_admin`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _student_detail_with_token = async (data) => {
  const requestObj = {
    path: `api/student/student_detail`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _update_student = async (data) => {
  const requestObj = {
    path: `api/student/update_student`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _change_password = async (data) => {
  const requestObj = {
    path: `api/app_api/change_password`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editAdmin = async (data) => {
  const requestObj = {
    path: `api/admin/edit_admin`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const logout = async (data) => {
  const requestObj = {
    path: `api/examplePath`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _logout = async () => {
  const requestObj = {
    path: `api/app_api/logout`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _login_as_admin = async (id) => {
  const requestObj = {
    path: `api/app_api/login_as/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
