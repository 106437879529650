import PropTypes from "prop-types";
// material
import {
  Box,
  Checkbox,
  TableSortLabel,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from "@mui/material";
import CircularProgressWithLabel from "src/components/loaders/CircularProgressWithLabel";
import {
  formatDateWithDay,
  formatDateWithOutDay,
  formatTime24Hours,
} from "src/utils/formatTime";
import { capitalizeFirstLetter } from "src/utils/formatText";
import React, { useRef } from "react";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { s3baseUrl } from "src/config/config";
import { useState } from "react";
import { Label } from "src/components";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataNotFoundAll from "src/components/GeneralComponents/DataNotFoundAll";
import DataNotFoundCustomMessage from "src/components/GeneralComponents/DataNotFoundCustomMessage";

// ----------------------------------------------------------------------

const ModalStyle = {
  width: "30%",
  height: "90vh",
  minWidth: "30%",
  // maxHeight: "80vh", // Set a maximum height for the modal
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 0,
  overflow: "hidden", // Hide vertical scroll
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#0c2e60",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AllAttendanceList({ attendance }) {
  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRowData) {
    setOpenModal(true);
    // setSelectedRow(selectedRowData);
  }

  return (
    <>
      <Card>
        {attendance.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Days</StyledTableCell>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell align="center">
                    Check In (min)
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Check Out (min)
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">Absent</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {attendance.map((attendanceData, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {formatDateWithDay(attendanceData.date)}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {formatTime24Hours(attendanceData.check_in)}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {formatTime24Hours(attendanceData.check_out)}
                    </StyledTableCell>

                    {/* <StyledTableCell align="center">
                      <Label
                        variant="outlined"
                        color={
                          (attendanceData.batch.absent === true && "error") ||
                          "success"
                        }
                      >
                        {attendanceData.batch.absent === true
                          ? "Absent"
                          : "Present"}
                      </Label>

                 
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          // <div className="my-5 py-5">
          //   <DataNotFoundAll />
          // </div> 
          <div className="my-5 py-5">
  <DataNotFoundCustomMessage 
    Heading={<span dangerouslySetInnerHTML={{__html: "<span class='text-primary'> Attendance Not Found! </span>"}} />}
    SubHeading={`Your Attendance is up to date. No Attendance found.`}
  />
</div>

        )}
      </Card>

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="All Instructors"
        StyleFromHeader={ModalStyle}
        componentToPassDown={
          <>
            {/* <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                paddingTop: 5,
              }}
            >
              {instructors.map((instructor, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt={instructor.name}
                          src={s3baseUrl + instructor.image}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary="&nbsp;"
                        secondary={`${capitalizeFirstLetter(
                          instructor.first_name
                        )} ${capitalizeFirstLetter(instructor.last_name)}`}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                );
              })}
            </List> */}
          </>
        }
      />
    </>
  );
}
