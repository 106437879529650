import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

function CircularProgressWithLabel({ value, size }) {
  const theme = useTheme();
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (value > 0) {
      const interval = setInterval(() => {
        setProgressValue((prevValue) => {
          const newValue = prevValue + 1;
          if (newValue >= value) {
            clearInterval(interval); // Stop the interval when reaching the desired value
          }
          return newValue;
        });
      }, 10); // Adjust the interval duration as needed

      return () => clearInterval(interval); // Cleanup function to clear the interval on component unmount
    }
  }, [value]);

  const calculateFontSize = () => {
    // Calculate the font size based on the progress value
    const baseFontSize = theme.typography.caption.fontSize; // Default font size from the theme
    const maxFontSize = size; // Maximum font size you want
    const calculatedFontSize =
      (progressValue / 100) * (maxFontSize - baseFontSize) + baseFontSize;
    return calculatedFontSize;
  };

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={progressValue}
        size={size}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          style={{ fontSize: `${calculateFontSize()}px` }}
        >
          {`${Math.round(progressValue)}%`}
        </Typography> */}
        <div class="circle-graph2" data-percent="80">
          <canvas width="400" height="400"></canvas>
          <b>{`${Math.round(progressValue)}%`}</b>
        </div>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
