import { invokeApi } from "src/utils";


export const _list_support_ticket_for_admin = async (data) => {

    const requestObj = {
      // path: `api/support_ticket/list_support_ticket_for_admin?page=0&limit=99999`,
      path: `api/support_ticket/role_base_support_tickets?page=0&limit=99999`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_support_ticket = async (id) => {
    const requestObj = {
      path: `api/support_ticket/delete_support_ticket/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

    export const _close_support_ticket = async (id) => {
    const requestObj = {
      path: `api/support_ticket/close_support_ticket/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  
  export const _detail_support_ticket = async (id) => {
    const requestObj = {
      path: `api/support_ticket/detail_support_ticket/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _add_support_ticket_comment = async (data) => {

    const requestObj = {
      path: `api/support_ticket_comment/add_support_ticket_comment`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_support_ticket = async (data) => {

    const requestObj = {
      path: `api/support_ticket/add_support_ticket`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_support_ticket_comment = async (id) => {
    const requestObj = {
      path: `api/support_ticket_comment/delete_support_ticket_comment/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };
  
//   export const _add_brand = async (data) => {

//     const requestObj = {
//       path: `api/brand/add_brand`,
//       method: "POST",
//       headers: {
//         // "Content-Type": "multipart/form-data",
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//       postData: data,
//     };
//     return invokeApi(requestObj);
//   };

//  export const _edit_brand = async (id, data) => {
//   const requestObj = {
//     path: `api/brand/edit_brand/` + id,
//     method: "PUT",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

//   export const _delete_brand = async (id) => {
//     const requestObj = {
//       path: `api/brand/delete_brand/` + id,
//       method: "DELETE",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
  
//     return invokeApi(requestObj);
//   };

//   export const _detail_brand = async (id) => {
//     const requestObj = {
//       path: `api/brand/detail_brand/` + id,
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
//     return invokeApi(requestObj);
//   };

//   export const _list_active_brand = async () => {
//     const requestObj = {
//       path: `api/brand/list_active_brand/`,
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
//     return invokeApi(requestObj);
//   };
