import PropTypes from "prop-types";
// material
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Grid,
  Badge,
} from "@mui/material";
import CircularProgressWithLabel from "src/components/loaders/CircularProgressWithLabel";
import {
  formatDateWithDay,
  formatDateWithOutDay,
  formatTime24Hours,
} from "src/utils/formatTime";
import { capitalizeFirstLetter } from "src/utils/formatText";
import React, { useRef } from "react";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { s3baseUrl } from "src/config/config";
import { useState } from "react";
import { Iconify, Label } from "src/components";
import CustomCard from "src/components/GeneralComponents/CustomCard";
import DataNotFoundAll from "src/components/GeneralComponents/DataNotFoundAll";
import DataNotFoundCustomMessage from "src/components/GeneralComponents/DataNotFoundCustomMessage";
import CustomCardAssignment from "src/components/GeneralComponents/CustomCardAssignment";
import { useNavigate } from "react-router-dom";
import CustomCardAssignmentFullLength from "src/components/GeneralComponents/CustomCardAssignmentFullLength";
import CustomCardAssignmentFullLengthNewDesign from "src/components/GeneralComponents/CustomCardAssignmentFullLengthNewDesign";

// ----------------------------------------------------------------------

const ModalStyle = {
  width: "30%",
  height: "90vh",
  minWidth: "30%",
  // maxHeight: "80vh", // Set a maximum height for the modal
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 0,
  overflow: "hidden", // Hide vertical scroll
};
const getIcon = (name) => <Iconify icon={name} width={25} height={25} />;

export default function BatchDetailAssignment({ assignments }) {
  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState({});
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRowData) {
    setOpenModal(true);
    setSelectedRow(selectedRowData);
  }

  const handleCardClick = (voucher) => {
    // handleOpenModal(voucher);
    navigate(
      `/assignments/assignment-submit/${voucher._id}?assignment_name=${voucher.title}`
    );
    // event.preventDefault();
    console.log("Card clicked! Voucher:  __voucher", voucher);
    // You can perform any action you want
  };

  return (
    <>
      {assignments.total_assignments.length > 0 ? (
        <div>
          {/* <Grid container spacing={1}> */}
          {assignments.total_assignments.map((assignment, index) => {
            return (
              <div className="mb-2" key={index}>
                <CustomCardAssignmentFullLengthNewDesign
                  // <CustomCardAssignmentFullLength
                  voucher={assignment}
                  Fee={assignment.total_marks}
                  Title={assignment.title}
                  voucherStatus={assignment.student.assignment_status}
                  dueDate={assignment.dead_line}
                  className="total-assignments-full hover_effect_for_batch_card"
                  handleCardClick={handleCardClick}
                  // handleDownloadClick={handleDownloadClick}
                  // onClick={(event) => handleCardClick(event, voucher)}
                  Icon={
                    assignment.status == 1
                      ? getIcon("mdi:book-open-blank-variant")
                      : assignment.status == 2
                      ? getIcon("mdi:book-open-blank-variant")
                      : getIcon("mdi:book-open-blank-variant")
                  }
                />
              </div>
              // <CustomCardAssignment
              // voucher={assignment}
              // Fee={assignment.total_marks}
              //   Title={assignment.title}
              //   voucherStatus={assignment.assignment_status}
              //   dueDate={assignment.dead_line}
              //   className="total-assignments hover_effect_for_batch_card"
              //   handleCardClick={handleCardClick}
              //   // onClick={(event) => handleCardClick(event, voucher)}
              //   Icon={
              //       assignment.status == 1
              //       ? getIcon("mdi:book-open-blank-variant")
              //       : assignment.status == 2
              //       ? getIcon("mdi:book-open-blank-variant")
              //       : getIcon("mdi:book-open-blank-variant")
              //   }

              // />
            );
          })}
        </div>
      ) : (
        <Card>
          {/* <div className="my-5 py-5">
            <DataNotFoundAll />
          </div> */}
          <div className="my-5 py-5">
            <DataNotFoundCustomMessage
              // Heading="Payment Status <span className='text-success'>OK!</span>"
              Heading={
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      "<span class='text-success'> Assignment Status OK! </span>",
                  }}
                />
              }
              SubHeading={`Your Assignments are up to date. No Assignments found.`}
            />
          </div>
        </Card>
      )}
      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Detail"
        StyleFromHeader={ModalStyle}
        componentToPassDown={
          <>
            <h1>
              {/* {selectedRow} */}
              work
            </h1>

            {/* <List
                                sx={{
                                  width: "100%",
                                  maxWidth: 360,
                                  bgcolor: "background.paper",
                                  paddingTop:5,
                                }}
                              >
                                {instructors.map((instructor, index) => {
                            
                                    return (
                                        <React.Fragment key={index}>
                                        <ListItem alignItems="flex-start">
                                          <ListItemAvatar>
                                            <Avatar
                                              alt={instructor.name}
                                              src={s3baseUrl + instructor.image}
                                            />
                                          </ListItemAvatar>
                                          <ListItemText
                                            primary="&nbsp;"
                                            secondary={`${capitalizeFirstLetter(
                                              instructor.first_name
                                            )} ${capitalizeFirstLetter(
                                              instructor.last_name
                                            )}`}
                                          />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        </React.Fragment>
                                    );
                              
                                })}
                              </List> */}
          </>
        }
      />
    </>
  );
}
