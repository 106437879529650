// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography, Icon } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
// components
import SummaryCard from "./components/SummaryCard";
import { CircularLoader, Iconify, Page } from "src/components";
//hooks
// import { useAppContext } from "src/hooks";
import { useEffect, useState } from "react";
import { _student_dashboard, dashboard } from "src/DAL/dashboard";
import { Link } from "react-router-dom";
import DashboardCard from "src/components/Dashboard/DashboardCard";
import { useAppContext } from "src/hooks";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
// ----------------------------------------------------------------------
const getIcon = (name) => <Iconify icon={name} width={25} height={25} />;

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

export default function Dashboard() {
  const theme = useTheme();
  // const { _get_user_profile } = useAppContext();
  const [dashboardData, setDashboardData] = useState("");

  const { _get_user_profile } = useAppContext();
  const profile = _get_user_profile();

  const [isLoading, setIsLoading] = useState(true);

  const DASHBOARD_DATA = [
    {
      color: "success",
      title: "Batches",
      count: dashboardData,
      icon: "ph:student",
    },
    {
      color: "info",
      title: "Courses",
      count: dashboardData,
      icon: "fe:book",
    },
    {
      color: "secondary",
      title: "Instructors",
      count: dashboardData,
      icon: "mdi:teacher",
    },
    {
      color: "primary",
      title: "Students",
      count: dashboardData,
      icon: "fe:users",
    },
  ];

  const updatedData = DASHBOARD_DATA.map((item) => {
    if (item.title === "Students") {
      return {
        ...item,
        count: dashboardData.total_students,
      };
    } else if (item.title === "Batches") {
      return {
        ...item,
        count: dashboardData.total_batches,
      };
    } else if (item.title === "Courses") {
      return {
        ...item,
        count: dashboardData.total_courses,
      };
    } else if (item.title === "Instructors") {
      return {
        ...item,
        count: dashboardData.total_instructors,
      };
    }

    return item;
  });
  const { enqueueSnackbar } = useSnackbar();
  const [cards, setCards] = useState([
    // {
    //   icon: getIcon("fluent:sport-american-football-24-filled"),
    //   counting: 0,
    //   title: "Sports",
    //   className: "total-products",
    // },
    {
      icon: getIcon("material-symbols:assignment-outline"),
      counting: 0,
      title: "Assignment",
      className: "total-orders",
    },
    {
      icon: getIcon("flat-color-icons:leave"),
      counting: 0,
      title: "Total Absents",
      className: "total-products",
    },
    // {
    //   icon: getIcon("ant-design:team-outlined"),
    //   counting: 0,
    //   title: "Players",
    //   className: "total-employees",
    // },
  ]);

  const [voucherCards, setVoucherCards] = useState([
    {
      icon: getIcon("tabler:file-dollar"),
      counting:  0,
      title: "Pending",
      className: "total-shipments",
    },
    {
      icon: getIcon("tabler:file-dollar"),
      counting:  0,
      title: "Clearance",
      className: "total-shipments",
    },
    {
      icon: getIcon("tabler:file-dollar"),
      counting:  0,
      title: "Paid",
      className: "total-paid",
    },
    {
      icon: getIcon("tabler:file-dollar"),
      counting: 0,
      title: "Refund",
      className: "total-employees",
    },
  ]);

  const fetchVoucherList = async () => {
    setIsLoading(true);
    const response = await _student_dashboard();

    console.log("response _student_dashboard", response);

    // setCourses(data.batch.courses);
    if (response.code === 200) {
      //   setAssignments(response.assignments);
      //   setAttendance(response.attendance);

      // const Pending = response.fee_vouchers.filter(
      //   (item) => item.voucher_status === 1
      // );
      // const Paid = response.fee_vouchers.filter(
      //   (item) => item.voucher_status === 2
      // );
      // const Clearance = response.fee_vouchers.filter(
      //   (item) => item.voucher_status === 3
      // );
      const Refund = response.fee_vouchers.filter(
        (item) => item.voucher_status === 4
      );

      setCards([
        {
          icon: getIcon("ph:student-bold"),
          counting: response.batches.length,
          title: "Enroll Batches",
          className: "total-vendors",
          path:"/batches"
        },
        {
          icon: getIcon("material-symbols:assignment-outline"),
          counting: response.pending_assignments,
          title: "Pending Assignment",
          className: "total-shipments",
          path:"/assignments"
        },
        {
          icon: getIcon("material-symbols:assignment-outline"),
          counting: response.submitted_assignments,
          title: "Submitted Assignment",
          className: "total-paid",
          path:"/assignments"
        },
        {
          icon: getIcon("material-symbols:assignment-outline"),
          counting: response.attendance?response.attendance.attendance.length:0,
          title: "Total Attendance",
          className: "total-orders",
          path:"/attendance"
        },
      ]);
      
      setVoucherCards([
        
       
        {
          icon: getIcon("tabler:file-dollar"),
          counting: response.voucher_counts.pending_vouchers_count,
          title: "Pending",
          className: "total-shipments",
          path:"/vouchers"
        },
        {
          icon: getIcon("tabler:file-dollar"),
          counting: response.voucher_counts.pending_clearance_vouchers_count,
          title: "Pending Clearance",
          className: "total-clearance",
          path:"/vouchers?type=1"
        },
        {
          icon: getIcon("tabler:file-dollar"),
          counting: response.voucher_counts.paid_vouchers_count,
          title: "Paid",
          className: "total-paid",
          path:"/vouchers?type=2"
        },
        {
          icon: getIcon("tabler:file-dollar"),
          counting: Refund.length,
          title: "Refund",
          className: "total-employees",
          path:"/vouchers?type=3"
          // path:"/vouchers"
        },
      ]);

      //   setBatchInfo(response.batch_info);
      //   setInstructors(response.batch_info.batch_instructors);

      setIsLoading(false);
      //   setPercentage(response.performance_percentage);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }; 

  useEffect(() => {
    // fetchDetailBatch();
    fetchVoucherList();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <h4 className="mb-5 mt-4">
          {capitalizeFirstLetter(profile.first_name)}, Welcome back
        </h4>

        <div className="mb-3">
          <Grid 
          container 
          // spacing={3} 
          // sx={{ px: 3 }}
          >
            <DashboardCard cards={cards} />
          </Grid>
        </div>

        <div className="mb-3">
        <Divider>
  {" "}
  <Chip
    label="Vouchers"
    sx={{
      backgroundColor: "#fff",
      // backgroundColor: theme.palette.info.main,
      // color: theme.palette.info.contrastText,
      // color: theme.palette.primary.contrastText,
      // color: "#000",
      color: "#1890ff",
      border: `1px solid ${theme.palette.info.main}`, // Add this line for outline
      fontWeight: 600,
    }}
  />
</Divider>

          <div style={{ marginTop: "15px" }}>
            <Grid 
            
            container
            // spacing={3} 
            // sx={{ px: 3 }}
            >
              <DashboardCard cards={voucherCards} />
            </Grid>
          </div>
        </div>
      </Container>
    </Page>
  );
}
