import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { delStudent } from "src/DAL/student";
import { _delete_brand } from "src/DAL/brand";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { _close_support_ticket, _delete_support_ticket } from "src/DAL/support_tickets";
// ----------------------------------------------------------------------

export default function SupportTicketMoreMenu({ row, students, onStudentsUpdate,type }) {
    console.log("row ______row ____test",row)
  const [openDelete, setOpenDelete] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const navigate = useNavigate();
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleCloseClick = () => {
    setIsOpen(false);
    setOpenClose(true);
  };

  const handleCloseOk = () => {
    handleCloseClickAPI(row);
    setOpenClose(false); // Call the onDelete function when the user confirms the delete action
  };

  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleCloseClickAPI(row) {
    //console.log(row);
    const result = await _close_support_ticket(row._id);
    if (result.code === 200) {

        navigate("/support-tickets");

    //   const updatedStudents = students.filter(
    //     (students) => students.id !== row._id
    //   );

    //   const updatedStudents2 = students.map(
    //     (student) => {
    //       if (student._id === row._id) {
    //         // If the batch id matches, create a new object with batch_status set to 1
    //         return {
    //           ...student,
    //           ticket_status: 1,
    //           // percentage: percentageMarks(formInputs.give_marks)
    //           //   ? percentageMarks(formInputs.give_marks)
    //           //   : "",
    //           // result_status: percentageMarksToFailOrPass(
    //           //   formInputs.give_marks
    //           // )
    //           //   ? percentageMarksToFailOrPass(formInputs.give_marks)
    //           //   : "",
    //         };
    //       } else {
    //         // For other objects, return them as they are
    //         return student;
    //       }
    //     }
    //   );

    //   if(type===0||type===1){
    //     onStudentsUpdate(updatedStudents);
    //   }else{
    //     onStudentsUpdate(updatedStudents2);
    //   }
      enqueueSnackbar("Closed Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  async function handleClick(row) {
    //console.log(row);
    const result = await _delete_support_ticket(row._id);
    if (result.code === 200) {

        navigate("/support-tickets");

    //   const updatedStudents = students.filter(
    //     (students) => students.id !== row._id
    //   );
    //   onStudentsUpdate(updatedStudents);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >

       {row.ticket_status !==1 ? (
        <MenuItem   sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }} onClick={handleCloseClick}>
          <ListItemIcon>
            <Iconify icon="charm:cross" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Close"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
          ): (
          
            ""
          )}

       
        <MenuItem   sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }} onClick={handleDeleteClick}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
          

      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete?"
        onOk={handleDeleteOk}
      />
      <DelPopup
        open={openClose}
        setOpen={setOpenClose}
        title="Close Ticket"
        message="Are you sure you want to Close Ticket?"
        onOk={handleCloseOk}
      />
    </>
  );
}
