import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import { Card, Typography, Grid, Badge, Chip, Button } from "@mui/material";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import {
  compareDateWithTodayDate,
  formatDateWithDay,
} from "src/utils/formatTime";
import { s3baseUrl } from "src/config/config";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(0, 0),
  borderRadius: "15px",
  padding: "5px 0px",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  marginTop: "0px",
  display: "flex",
  borderRadius: "25%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  backgroundColor: "#FFFFFF",
  marginTop: "-3px",
}));

export default function CustomCardAssignment({
  voucher,
  Fee,
  Title,
  className,
  Icon,
  voucherStatus,
  dueDate,
  handleCardClick,
  // handleDownloadClick,
}) {
  const [invoiceLink, setInvoiceLink] = useState(`${s3baseUrl}${voucher.student.assignment_url}`);
  
  const handleDownloadClick = () => {
    // Use the "click" method to trigger the download
    const link = document.createElement("a");
    link.href = invoiceLink;
    link.download = "downloaded-file"; // Set the desired file name
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Recommended for security reasons when using target="_blank"
    link.click();
  };
 
  const handleBadgeClick = (event) => {
    // Stop the propagation of the click event to prevent it from reaching the parent
    event.stopPropagation();
    handleDownloadClick();
  };

  console.log("voucher ____voucher",voucher);
  return (
    <Grid item xs={12} sm={6} md={3}>
      <RootStyle className={className}>
        <Grid
          container
          sx={{ pt: 0 }}
          // onClick={(event) => handleCardClick(event, voucher)}
          onClick={(event) => {
            console.log("Card clicked:");
            handleCardClick(voucher);
          }}
        >
          <Grid item xs={3} sx={{ pl: 1 }}>
            {voucherStatus == 2 ? (
              <Button
                variant="contained"
                color="info"
                // startIcon={<GetAppIcon />}
                onClick={handleBadgeClick}
                size="small"
                sx={{
                  fontSize: "7px", // or any other valid CSS font size value
                }}
              >
                Download
              </Button>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={9} sx={{ color: "white" }}>
            <Chip
              label={formatDateWithDay(dueDate)}
              color={
                voucherStatus == 2
                  ? "success"
                  : compareDateWithTodayDate(dueDate) == 1
                  ? "warning"
                  : compareDateWithTodayDate(dueDate) == 0
                  ? "error"
                  : "info"
              }
            />
          </Grid>

          <Grid item xs={3} sx={{ pl: 1 , mt: 1 }}>
            <Badge
              //  onClick={handleBadgeClick}
              badgeContent={
                voucherStatus == 1
                  ? "Pending"
                  : voucherStatus == 2
                  ? "Submitted"
                  : "Pending"
              }
              color={
                voucherStatus == 1
                  ? "warning"
                  : voucherStatus == 2
                  ? "success"
                  : "primary"
              }
            >
              <IconWrapperStyle>{Icon}</IconWrapperStyle>
            </Badge>
          </Grid>
          <Grid item xs={9} sx={{ color: "white", pt: 1  }}>
            <Typography variant="h6" sx={{ opacity: 1.0 }}>
              {Fee} Total Marks
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 1.0 }}>
              {capitalizeFirstLetter(Title)}
            </Typography>
          </Grid>
        </Grid>
      </RootStyle>
    </Grid>
  );
}
