import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Avatar,
  Badge,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Stack,
  Tooltip,
  Zoom,
  LinearProgress,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DateTimePicker } from "@mui/x-date-pickers";
import { _upload_attendance } from "src/DAL/attendance";
import { CircularLoader, Label, Page } from "src/components";
import { addImage } from "src/DAL/uploadfiles";
import {
  _detail_student_assignment,
  _upload_assignment_from_student,
} from "src/DAL/assignment";
import { _detail_leave_request_for_student } from "src/DAL/leave_requests";
import { s3baseUrl } from "src/config/config";
import TinyEditor from "src/components/Ckeditor";
import { htmlReturnFromCKEditor, truncateString } from "src/utils/formatText";
import {
  formatDateTimeWithOutZone,
  formatDateWithDay,
  formatDateWithOutDay,
} from "src/utils/formatTime";
import CustomImageUploaderMulti from "src/components/GeneralComponents/CustomImageUploaderMulti";
import { getFileIcon } from "src/utils/formatOther";

const Input = styled("input")({
  display: "none",
});
function AssignmentSubmitComponent({
  assignment_id,
  assignment_title,
  handleCardClick,
  selectedRow,
}) {
  const navigate = useNavigate();
  const moment = require("moment");
  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [allGames, setAllGames] = useState([]);
  const [allClubs, setAllClubs] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [acceptableFile, setAcceptableFile] = useState("");
  const [message, setMessage] = useState("");
  const [invoiceLink, setInvoiceLink] = useState("");
  const [studentLink, setStudentLink] = useState("");
  const [oldLinkURL, setoldLinkURL] = useState("");

  const [showButton, setShowButton] = useState(false);

  const [color, setColor] = useState("success");
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState([]);
  const [selectedRowStudent, setSelectedRowStudent] = useState(selectedRow);
  const param = useParams();
  let { state } = useLocation();
  //   const _ID = param.id;
  const _ID = assignment_id;

  //   const urlParams = new URLSearchParams(window.location.search);
  let assignmentName = assignment_title;
  //   if (urlParams.get("assignment_name") != null) {
  //     assignmentName = urlParams.get("assignment_name");
  //   }

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    image: {},
  });

  const [oldImage, setOldImage] = useState("");
  const [detailDescriptionEditor, setDetailDescriptionEditor] = useState("");
  const [formInputs, setFormInputs] = useState({
    title: "",
    batch: {
      _id: "",
      name: "",
    },
    assigned_by: {
      _id: "",
      first_name: "",
      last_name: "",
    },
    // assigned_date: "2023-12-08T00:00:00.000Z",
    assigned_date: "",
    dead_line: "2023-12-08T00:00:00.000Z",
    total_marks: 0,
    status: true,
    submitted_by: [],
    question_paper_url: "",
    note: "",
  });

  const [inputs, setInputs] = useState({
    name: "",
    game_id: "",
    game: "",
    club_id: "",
    country: "Ireland",
    status: true,

    first_name: "",
    last_name: "",
    gender: "male",
    age: "",
    // dob: "",
    // height: "",
    // weight: "",
    // disability: "No",
    // skill: "",
  });

  const myStyleA = {
    textTransform: "capitalize",
    fontWeight: 600, // Add this line for bold font weight
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "center", // Add text alignment to center
  };

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  const myStyleHeader2 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    fontSize: "1.3rem", // Adjust the font size as needed
    color: "#fff!important",
  };

  const myStyleHeader3 = {
    backgroundColor: "#0c2e60",
    color: "#fff",
  };
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function formatDate(inputDate) {
    // Parse the input date string
    const parsedDate = moment(inputDate);

    // Format the date as "Month Day, Year"
    const formattedDate = parsedDate.format("MMMM DD, YYYY");

    return formattedDate;
  }

  const fileChangedHandler = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.size <= 1 * 1024 * 1024) {
      // If the file is 5MB or smaller
      setImage(selectedFile);
      setPreviews(URL.createObjectURL(selectedFile));
      setAcceptableFile("true");
      setMessage("Acceptable File");
      setColor("success");
    } else if (selectedFile && selectedFile.size > 1 * 1024 * 1024) {
      // If the file is larger than 5MB
      setAcceptableFile("false");
      setMessage("Error: The file size exceeds the maximum allowed limit.");
      setColor("danger");
    }

    console.log("event", event);
    console.log("event.target.files[0]", selectedFile);
    console.log(
      "URL.createObjectURL(event.target.files[0])",
      URL.createObjectURL(selectedFile)
    );
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handle_format_data = (data) => {
    // const defaultDate = new Date(data.dob);
    console.log("handle_format_data", data);
    let clubID = "";
    let Gender = "male";
    if (data.club) {
      clubID = data.club._id;
    }

    if (data.gender === "Female" || data.gender === "female") {
      Gender = "female";
    }

    setInputs({
      ...data,
      game_id: data.game._id,
      club_id: clubID,
      gender: Gender,
    });

    setPreviews(data.image.src);
  };

  const get_detail_player = async () => {
    setIsLoading(true);
    const response = await _detail_student_assignment(_ID);

    console.log("response _detail_student_assignment", response);
    if (response.code === 200) {
      let AssignmentData = response.assignment;

      let newAssignmentData = {
        ...AssignmentData,
        title: AssignmentData.title,
        batch_name: AssignmentData.batch.name,
        // student: AssignmentData.student.name,
        student_name: AssignmentData.student.name,
        date: formatDate(AssignmentData.date),
        dead_line: formatDate(AssignmentData.assigned_date),
        assignment_status: AssignmentData.assignment_status,
        total_marks: AssignmentData.total_marks,
        obtained_marks:
          AssignmentData.obtained_marks === null
            ? ""
            : assignment.obtained_marks,
      };

      console.log(
        "response newAssignmentData _detail_student_assignment",
        newAssignmentData
      );
      setAssignment(newAssignmentData);

      //   handle_format_data(newGameData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inside");
    let error = "";
    const formData = new FormData();

    if (!detailDescriptionEditor || detailDescriptionEditor === "") {
      error = "Student Note is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    setIsLoading(true);

    const responseAssignment = await _upload_assignment_from_student(_ID, {
      // assignment_url: response.path,
      assignment_url: pathOfUploadedFile,
      student_note: detailDescriptionEditor,
    });

    console.log(
      "response _upload_assignment_from_student test",
      responseAssignment
    );
    if (responseAssignment.code === 200) {
      enqueueSnackbar(responseAssignment.message, { variant: "success" });
      navigate(-1);

      // Set timer to execute the code after 2 seconds
      setTimeout(() => {
        // Your code to be executed after 2 seconds
        // For example:
        setIsLoading(false);
        // console.log('Code executed after 2 seconds');
      }, 2000); // 2000 milliseconds = 2 seconds
    } else {
      enqueueSnackbar(responseAssignment.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const fetchDetailAssignment = async () => {
    setIsLoading(true);
    const response = await _detail_student_assignment(_ID);
    console.log("response _detail_student_assignment", response);
    if (response.code === 200) {
      setSelectedRowStudent(response.assignment);

      const modifiedFiles = response.assignment.student.assignment_url.map(
        ({ _id, ...rest }) => rest
      );
      setPathOfUploadedFile(modifiedFiles);

      setFormInputs({
        ...formInputs,
        ...response.assignment,
        // leave_date: defaultStartDate,
        // student_id: response.leave_request.student._id,
        // leave_reason: capitalizeFirstLetter(
        //   response.leave_request.leave_reason
        // ),
        // batch_id: batchIds, // Initialize as an empty array
      });

      setInvoiceLink(
        response.assignment.question_paper_url &&
          response.assignment.question_paper_url !== "" &&
          response.assignment.question_paper_url !== null
          ? response.assignment.question_paper_url
          : null
      );
      setDetailDescriptionEditor(response.assignment.student.student_note);
      setoldLinkURL(response.assignment.student.assignment_url);
      setShowButton(
        response.assignment.student.assignment_status === 1 ? true : false
      );

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailAssignment();
  }, []);

  const handleDownloadClick = () => {
    // Use the "click" method to trigger the download
    const link = document.createElement("a");
    link.href = invoiceLink;
    link.download = "downloaded-file"; // Set the desired file name
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Recommended for security reasons when using target="_blank"
    link.click();
  };

  const handleBadgeClick = (event) => {
    // Stop the propagation of the click event to prevent it from reaching the parent
    event.stopPropagation();
    handleDownloadClick();
  };

  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevents the default behavior (opening the link)

    // You can add any specific functionality you want when the link is clicked
    // For example, redirecting to the download URL or opening it in a new tab
    window.open(s3baseUrl + oldLinkURL, "_blank");
  };

  //===================================================================
  //   if (isLoading) {
  //     return <CircularLoader color="green" />;
  //   }

  console.log("selectedRowStudent    _____selectedRowStudent  __assignment",selectedRowStudent);
  if (isLoading) {
    return (
      <div className=" ">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <Page title="Assignment Submit">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        <div className="container ">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                marginTop: "10px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#fbfbfc", // Set the border style here
                  }}
                >
                  <TableCell align="left" style={myStyleHeader}>
                    Title
                  </TableCell>
                  <TableCell align="left" style={myStyleHeader}>
                    Batch
                  </TableCell>
                  <TableCell align="left" style={myStyleHeader}>
                    Assigned Date
                  </TableCell>
                  <TableCell align="left" style={myStyleHeader}>
                    Dead Line Date
                  </TableCell>
                  <TableCell align="left" style={myStyleHeader}>
                    Total Marks
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.title)}
                  </TableCell>

                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.batch.name)}
                  </TableCell>

                  <TableCell align="left">
                    {selectedRowStudent.assigned_date
                      ? formatDateWithDay(selectedRowStudent.assigned_date)
                      : " _ _ "}
                  </TableCell>

                  <TableCell align="left">
                    {selectedRowStudent.dead_line
                      ? formatDateWithDay(selectedRowStudent.dead_line)
                      : " _ _ "}
                  </TableCell>

                  <TableCell align="left">
                    {selectedRowStudent.total_marks}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {selectedRowStudent.question_paper_url.length >0 ? (
              <Table
                sx={{
                  minWidth: "100%",
                  marginTop: "10px",
                  border: "1px solid #f1f3f4", // Set the border style here
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#fbfbfc", // Set the border style here
                    }}
                  >
                    <TableCell align="left" style={myStyleHeader}>
                      Download Links
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <div
                        className={`col-12 col-sm-12 `}
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {selectedRowStudent.question_paper_url.map(
                          (filePath, index) => (
                            <Box
                              key={index}
                              position="relative"
                              display="inline-block"
                              border="1px solid #cccccc55"
                              p={1}
                              //   mb={2} // Add margin bottom for spacing between files
                              style={{ marginRight: "10px" }} // Adjust spacing between files
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                ({index + 1}) &nbsp;
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginRight: "5px", // Adjust spacing between icon and text
                                  }}
                                >
                                  <a
                                    href={s3baseUrl + filePath.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {getFileIcon(filePath.name, filePath.path)}
                                  </a>
                                </Box>
                                <Box>
                                  <a
                                    href={s3baseUrl + filePath.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Tooltip
                                      TransitionComponent={Zoom}
                                      title={
                                        filePath.name.length > 15
                                          ? capitalizeFirstLetter(filePath.name)
                                          : ""
                                      }
                                      arrow
                                    >
                                      {capitalizeFirstLetter(
                                        truncateString(filePath.name, 15)
                                      )}
                                    </Tooltip>
                                  </a>{" "}
                                  &nbsp;&nbsp;
                                </Box>
                              </div>
                            </Box>
                          )
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              ""
            )}

            {selectedRowStudent.assignment_note !== "" &&
            selectedRowStudent.assignment_note !== null ? (
              <Table
                sx={{
                  minWidth: "100%",
                  marginTop: "10px",
                  border: "1px solid #f1f3f4", // Set the border style here
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#fbfbfc", // Set the border style here
                    }}
                  >
                    <TableCell align="left" style={myStyleHeader}>
                      Assignment Note
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      {selectedRowStudent.assignment_note !== "" &&
                      selectedRowStudent.assignment_note !== null
                        ? htmlReturnFromCKEditor(
                            selectedRowStudent.assignment_note
                          )
                        : " - - "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              ""
            )}

            {showButton ? (
              <div className="mt-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="small-outlined-button"
                  variant="outlined"
                  color={"info"}
                  onClick={() => handleCardClick(selectedRow)}
                >
                  Submit Your Assignment
                </Button>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
        </div>
      </Grid>
    </Page>
  );
}

export default AssignmentSubmitComponent;
