import PropTypes from "prop-types";
// material
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import CircularProgressWithLabel from "src/components/loaders/CircularProgressWithLabel";
import { formatDateWithOutDay, formatTime24Hours } from "src/utils/formatTime";
import { capitalizeFirstLetter } from "src/utils/formatText";
import React, { useRef } from "react";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { s3baseUrl } from "src/config/config";
import { useState } from "react";
import { Label } from "src/components";

// ----------------------------------------------------------------------

const ModalStyle = {
  width: "30%",
  height: "90vh",
  minWidth: "30%",
  // maxHeight: "80vh", // Set a maximum height for the modal
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 0,
  overflow: "hidden", // Hide vertical scroll
};

export default function BatchDetailTabInfo({
  instructors,
  batchInfo,
  percentage,
}) {
  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRowData) {
    setOpenModal(true);
    // setSelectedRow(selectedRowData);
  }

  return (
    <>
      <Card>
        <div className="row">
          <div className="col-12 col-lg-12 col-xl-12">
            <div className="card flex-fill comman-shadow">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    {/* <h5 className="card-title">Today’s Lesson</h5> */}
                    <h5 className="card-title"> Total Progress </h5>
                  </div>
                </div>
              </div>

              <div className="dash-circle1">
                <div className="row">
                  <div className="col-lg-3 col-md-3 dash-widget1">
                    <div className="circle-bar circle-bar2">
                      <CircularProgressWithLabel value={percentage} size={90} />
                      {/* <div className="circle-graph2" data-percent="80">
                          <canvas width="400" height="400"></canvas>
                          <b>80%</b>
                    
                        </div> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="dash-details">
                      <div className="lesson-activity">
                        <div className="lesson-imgs">
                          {/* <img src="./Preskool - Dashboard_files/lesson-icon-01.svg" alt=""> */}
                        </div>
                        <div className="views-lesson">
                          <div className="batch_info_heading">Batch</div>
                          <div className="batch_info_sub_text">
                            {capitalizeFirstLetter(batchInfo.batch_name)}
                          </div>
                        </div>
                      </div>

                      <div className="lesson-activity">
                        <div className="lesson-imgs">
                          {/* <img src="./Preskool - Dashboard_files/lesson-icon-03.svg" alt=""> */}
                        </div>
                        <div className="views-lesson">
                          {/* <h4>Course</h4>
                          <h5>{capitalizeFirstLetter(batchInfo.course_title)}</h5> */}
                          <div className="batch_info_heading">Course</div>
                          <div className="batch_info_sub_text">
                            {capitalizeFirstLetter(batchInfo.course_title)}
                          </div>
                        </div>
                      </div>

                      <div className="lesson-activity">
                        <div className="lesson-imgs">
                          {/* <img src="./Preskool - Dashboard_files/lesson-icon-02.svg" alt=""> */}
                        </div>
                        <div className="views-lesson">
                          <div className="batch_info_heading">Lectures</div>
                          <div className="batch_info_sub_text">
                            {batchInfo.course_lectures}
                          </div>
                        </div>
                      </div>

                      <div className="lesson-activity">
                        <div className="lesson-imgs">
                          {/* <img src="./Preskool - Dashboard_files/lesson-icon-04.svg" alt=""> */}
                        </div>
                        <div className="views-lesson">
                          <div className="batch_info_heading">Time</div>
                          <div className="batch_info_sub_text">
                            {formatTime24Hours(batchInfo.start_time_class)} To{" "}
                            {formatTime24Hours(batchInfo.end_time_class)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3">
                    <div className="dash-details">
                      <div className="lesson-activity">
                        <div className="lesson-imgs">
                          {/* <img src="./Preskool - Dashboard_files/lesson-icon-04.svg" alt=""> */}
                        </div>
                        <div className="views-lesson">
                          <div className="batch_info_heading">Status</div>
                          <div className="batch_info_sub_text">
                            {" "}
                            <div style={{ marginTop: "3px" }}>
                              <Label
                                variant="outlined"
                                color={
                                  (batchInfo.batch_status === 1 && "warning") ||
                                  (batchInfo.batch_status === 4 && "success") ||
                                  (batchInfo.batch_status === 5 && "info") ||
                                  "info"
                                }
                              >
                                {batchInfo.batch_status === 1
                                  ? "Pending"
                                  : batchInfo.batch_status === 2
                                  ? "Confirm"
                                  : batchInfo.batch_status === 3
                                  ? "Started"
                                  : batchInfo.batch_status === 4
                                  ? "Completed"
                                  : batchInfo.batch_status === 5
                                  ? "Freeze"
                                  : "Not Set"}
                              </Label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="lesson-activity">
                        <div className="lesson-imgs">
                          {/* <img src="./Preskool - Dashboard_files/lesson-icon-05.svg" alt=""> */}
                        </div>
                        <div className="views-lesson">
                        <div className="batch_info_heading">Start Date</div>
                          <div className="batch_info_sub_text">
                          {formatDateWithOutDay(batchInfo.start_date_batch)}
                          </div>
                          
                        </div>
                      </div>

                      <div className="lesson-activity">
                        <div className="lesson-imgs">
                          {/* <img src="./Preskool - Dashboard_files/lesson-icon-05.svg" alt=""> */}
                        </div>
                        <div className="views-lesson">
                        <div className="batch_info_heading">End Date</div>
                          <div className="batch_info_sub_text">
                          {formatDateWithOutDay(batchInfo.end_date_batch)}
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3">
                    <div className="dash-details">
                      <div className="lesson-activity">
                        <div className="lesson-imgs">
                          {/* <img src="./Preskool - Dashboard_files/lesson-icon-05.svg" alt=""> */}
                        </div>
                        <div className="views-lesson">
                        <div className="batch_info_heading">Staff</div>
                          {/* <div className="batch_info_sub_text">
                          {formatDateWithOutDay(batchInfo.end_date_batch)}
                          </div> */}

                        
                          <div style={{ marginLeft: "-15px" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                              }}
                            >
                              {instructors.map((instructor, index) => {
                                if (index < 3) {
                                  return (
                                    <React.Fragment key={index}>
                                      <ListItem
                                        alignItems="flex-start"
                                        sx={{ marginTop: "-8px" }}
                                      >
                                        <ListItemAvatar>
                                          <Avatar
                                            alt={instructor.name}
                                            src={s3baseUrl + instructor.image}
                                          />
                                        </ListItemAvatar>
                                        <ListItemText
                                          primary="&nbsp;"
                                          secondary={`${capitalizeFirstLetter(
                                            instructor.first_name
                                          )} ${capitalizeFirstLetter(
                                            instructor.last_name
                                          )}`}
                                        />
                                      </ListItem>
                                      <Divider variant="inset" component="li" />
                                    </React.Fragment>
                                  );
                                } else {
                                  if (index == 3) {
                                    return (
                                      <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                          {/* <Avatar
                                            alt={instructor.name}
                                            src={s3baseUrl + instructor.image}
                                          /> */}
                                        </ListItemAvatar>
                                        {/* <ListItemText
                                          primary="&nbsp;"
                                          secondary={`${capitalizeFirstLetter(
                                            instructor.first_name
                                          )} ${capitalizeFirstLetter(
                                            instructor.last_name
                                          )}`}
                                        /> */}
                                        <Button
                                          variant="outlined"
                                          color="secondary"
                                          onClick={() => handleOpenModal("")}
                                          size="small" // Adjust the size as needed (small, medium, large)
                                          style={{ marginLeft: "40px" }} // Adjust the margin-left as needed
                                        >
                                          View All
                                        </Button>
                                      </ListItem>

                                      //   <div
                                      //   key={index}
                                      //     className="on_click_text mt-2"
                                      //     // style={{
                                      //     //   cursor: "pointer",
                                      //     // }}
                                      //     onClick={() => handleOpenModal("")}
                                      //   >
                                      //     {capitalizeFirstLetter("All Instructors")}
                                      //   </div>
                                    );
                                  } else {
                                    return null; // Skip rendering if index is not less than 2
                                  }
                                }
                              })}
                            </List>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-3 col-md-3 d-flex align-items-center justify-content-center">
                      <div className="skip-group">
                        <button type="submit" className="btn btn-info skip-btn">
                          skip
                        </button>
                        <button type="submit" className="btn btn-info continue-btn">
                          Continue
                        </button>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="All Instructors"
        StyleFromHeader={ModalStyle}
        componentToPassDown={
          <>
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                paddingTop: 5,
              }}
            >
              {instructors.map((instructor, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt={instructor.name}
                          src={s3baseUrl + instructor.image}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary="&nbsp;"
                        secondary={`${capitalizeFirstLetter(
                          instructor.first_name
                        )} ${capitalizeFirstLetter(instructor.last_name)}`}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                );
              })}
            </List>
          </>
        }
      />
    </>
  );
}
