import PropTypes from "prop-types";
import React from "react";
import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@iconify/react";
import { Form, FormikProvider } from "formik";
import closeFill from "@iconify/icons-eva/close-fill";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  Badge,
  Checkbox,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

import Scrollbar from "./Scrollbar";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// ----------------------------------------------------------------------
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomModalDel({
  isOpenModal,
  handleCloseModal,
  componentToPassDown,
  pageTitle,
  customStyle
}) {

    const isSmallScreen = useMediaQuery("(max-width:617px)");

const modalWidth = isSmallScreen ? "60%" : "45%";
const minWidth = isSmallScreen ? "60%" : "45%";

 


const style = {
    width: "100%",
    minWidth: "100%",
    height: "100%",
  // //   maxHeight: "89vh", // Set a maximum height for the modal
  //   overflowY: "auto", // Enable vertical scrolling
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   bgcolor: "background.paper",
  //   boxShadow: 24,
  //   p: 0,
  //   pt:1,
  //   pb:1,
  };
  
   
  const style2 = {
    width: modalWidth,
    minWidth: minWidth,
    // maxHeight: "80vh", // Set a maximum height for the modal
  
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
  //   border: "2px solid #000",
    boxShadow: 24,
    // p: 0,
    overflow: "hidden", // Hide vertical scroll
  };

  return (
    <>
      <Modal
        open={isOpenModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        transitioncomponent={Transition}
      >
        <Box sx={customStyle?customStyle:style2} className="custom-scrollbar">
          <AppBar position="fixed">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {pageTitle}
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseModal}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box sx={style} className="custom-scrollbar">
            <Scrollbar>
              <div className="modal-component">{componentToPassDown}</div>
            </Scrollbar>
            {/* <StudentDetail studentID={selectedRowStudent} /> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
}
