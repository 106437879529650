import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import {
  Card,
  Typography,
  Grid,
  Badge,
  Chip,
  Button,
  useTheme,
  Box,
  Avatar,
} from "@mui/material";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { fShortenNumber, formatDateTimeWithOutZone } from "src/utils";
import {
  compareDateWithTodayDate,
  formatDateWithDay,
  getTimeDifference,
} from "src/utils/formatTime";
import { s3baseUrl } from "src/config/config";
import Label from "../Label";
import Iconify from "../Iconify";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(0, 0),
  borderRadius: "15px",
  padding: "5px 2px",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  marginTop: "0px",
  display: "flex",
  borderRadius: "25%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  //   backgroundColor: "#FFFFFF",
  backgroundColor: "#f2f3f5",
  marginTop: "-3px",
}));

export default function CustomCardAssignmentFullLengthNewDesign({
  voucher,
  Fee,
  Title,
  className,
  Icon,
  voucherStatus,
  dueDate,
  handleCardClick,
  // handleDownloadClick,
  //
  //
  //

  title1,
  count1,
  cardBGColor,
  cardTextColor,

  iconBGColor,
  iconTextColor,

  icon,
  color = "primary",
  sx,
  ...other
}) {
  const [invoiceLink, setInvoiceLink] = useState(
    `${s3baseUrl}${voucher.student.assignment_url}`
  );
  const theme = useTheme(); // Use useTheme hook to access the theme

  const handleDownloadClick = () => {
    // Use the "click" method to trigger the download
    const link = document.createElement("a");
    link.href = invoiceLink;
    link.download = "downloaded-file"; // Set the desired file name
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Recommended for security reasons when using target="_blank"
    link.click();
  };

  const handleBadgeClick = (event) => {
    // Stop the propagation of the click event to prevent it from reaching the parent
    event.stopPropagation();
    handleDownloadClick();
  };

  console.log("voucher ____voucher", voucher);
  return (
    <Card
      onClick={(event) => {
        console.log("Card clicked:");
        handleCardClick(voucher);
      }}
      sx={{ cursor: "pointer" }}
    >
      <div className="row p-2 pt-3 pb-2">
        {/* First */}
        <div className="col-12 col-md-3 mb-2">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={"row.user.name"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                // color: iconTextColor,
                // backgroundImage: `linear-gradient(135deg, ${iconBGColor} 0%, ${iconBGColor} 100%)`,
                color: "#fbb03c",
                backgroundImage: `linear-gradient(135deg, #fff3e0 0%, #fff3e0 100%)`,
              }}
            >
              <Iconify icon={"material-symbols-light:menu-book"} width={24} height={24} />
            </Avatar>
 
            <Box sx={{ marginLeft: "10px" }}>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Title
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {capitalizeFirstLetter(Title)}
              </Typography>
            </Box>
          </Box>
        </div>
        {/* Second */}
        <div className="col-12 col-md-3 mb-2">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={"row.user.name"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                color: "#fd7771",
                backgroundImage: `linear-gradient(135deg, #ffe8e7 0%, #ffe8e7 100%)`,
              }}
            >
              <Iconify icon={"ph:student-bold"} width={20} height={20} />
            </Avatar>
 
            <Box sx={{ marginLeft: "10px" }}>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Batch
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {capitalizeFirstLetter(voucher.batch.name)}
              </Typography>
            </Box>
          </Box>
        </div>
        {/* Third */}
        <div className="col-12 col-md-3 mb-2">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={"row.user.name"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                color: "#9561ff",
                backgroundImage: `linear-gradient(135deg, #efe7ff 0%, #efe7ff 100%)`,
              }}
            >
              <Iconify icon={"carbon:time"} width={20} height={20} />
            </Avatar>

            <Box sx={{ marginLeft: "10px" }}>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Dead Line Date
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {formatDateWithDay(dueDate)}
              </Typography>
            </Box>
          </Box>
        </div>
        {/* Forth */}
        <div className="col-12 col-md-3 mb-2">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={"row.user.name"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                color: "#4070ed",
                backgroundImage: `linear-gradient(135deg, #e7edff 0%, #e7edff 100%)`,
              }}
            >
              <Iconify icon={"carbon:result"} width={20} height={20} />
            </Avatar>

            <Box sx={{ marginLeft: "10px" }}>

              <Typography variant="body1" sx={{ fontWeight: "600" }}>
              {voucher.student.obtained_marks !== null
                ? `${voucher.student.obtained_marks}/${Fee} Marks`
                : `Total Marks`}
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {voucher.student.result_status=== 3 || voucher.student.result_status===2   ? (
              <Label
                variant="outlined"
                color={
                  voucher.student.result_status === 3 && voucherStatus ===3 ? "error" : 
                  voucher.student.result_status === 2 && voucherStatus ===3 ? "success" : 
                  "warning"}
              >
                {`${
                  voucher.student.result_status === 3 && voucherStatus ===3 ? "Failed" :
                  voucher.student.result_status === 2 && voucherStatus ===3 ? "Pass" :
                  "Pending"
                }`}
              </Label>
            ) : (
              ""
            )}
            <span style={{fontWeight:500}}> 
              {voucher.student.obtained_marks !== null && voucherStatus ===3
                ? `    ${voucher.student.percentage}% `
                : ``}
                </span>
{/* Pending / Submitted */}
                {voucherStatus !==3? `${Fee}` :""}
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
    </Card>
  );
}
