import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Tooltip,
  Badge,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent, listStudentSearch } from "src/DAL/student";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterStudent from "./components/FilterStudent";
import { _list_attendance } from "src/DAL/attendance";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { useAppContext } from "src/hooks";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { useSnackbar } from "notistack";
import { _list_assignment } from "src/DAL/assignment";
import { formatDateWithDay } from "src/utils/formatTime";
import {
  _list_leave_request_for_admin,
  _list_leave_request_for_student,
} from "src/DAL/leave_requests";
import LeaveRequestDetail from "./LeaveRequestDetail";
import DataNotFoundCustomMessage from "src/components/GeneralComponents/DataNotFoundCustomMessage";
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "student_name", label: "Student Name", alignRight: false },
  { id: "leave_date", label: "Leave Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },

  //   { id: "batch", label: "Batch", alignRight: false },
  //   { id: "total_marks", label: "Total Marks", alignRight: false },

  // { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function LeaveRequestList() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const moment = require("moment");
  const [order, setOrder] = useState("asc");
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const myElementRef = useRef(null);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const [trashStudentCount, setTrashStudentCount] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/leave-requests/add-leave");
  };

  const handleUploadAttendance = () => {
    navigate("/attendance/upload-attendance");
  };
  const handleMoveToTrash = () => {
    navigate("/trash/");
  };

  function formatTime(Time) {
    // Parse the time strings using moment
    const startMoment = moment(Time, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime}`;

    return formattedTime;
  }

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await _list_leave_request_for_student();

    console.log("response _list_leave_request_for_student", response);
    if (response.code === 200) {
      //   const combinedAttendance = response.attendance.map(item => {
      //     const studentName = item.student.name;
      //     const studentID = item.student._id;
      //     return item.attendance.map(attendanceRecord => ({
      //         ...attendanceRecord,
      //         name: studentName,
      //         student_id: studentID,
      //     }));

      // }).reduce((accumulator, currentAttendance) => accumulator.concat(currentAttendance), []);

      // console.log('combinedAttendance ___combinedAttendance',combinedAttendance);

      setStudents(response.leave_request);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const SearchStudents = async (searchText) => {
    // const response = await listStudent();
    // setIsLoading(true);
    // const response = await listStudentSearch(searchText);
    const response = await _list_leave_request_for_student();
    if (response.code === 200) {
      // Use map to transform the response data
      // const updatedResponse = response.attendance.map(item => {
      //   const studentName = item.student.name;

      // const combinedAttendance = response.attendance
      //   .map((item) => {
      //     const studentName = item.student.name;
      //     const studentID = item.student._id;

      //     return item.attendance.map((attendanceRecord) => ({
      //       ...attendanceRecord,
      //       name: studentName,
      //       student_id: studentID,
      //     }));
      //   })
      //   .reduce(
      //     (accumulator, currentAttendance) =>
      //       accumulator.concat(currentAttendance),
      //     []
      //   );

      // Now, combinedAttendance contains all attendance records with student names in one array
      // console.log(combinedAttendance);

      // Now, updatedResponse contains the updated attendance records with student names
      // console.log(
      //   "combinedAttendance ___combinedAttendance",
      //   combinedAttendance
      // );

      // setTrashStudentCount(response.trash_student_count);

      setStudents(response.leave_request);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      console.log("Search: typeOf", typeof searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  function formatDate(inputDate) {
    // Parse the input date string
    const parsedDate = moment(inputDate);

    // Format the date as "Tue, Sep 05, 2023"
    const formattedDate = parsedDate.format("ddd, MMM DD, YYYY");

    return formattedDate;
  }
  useEffect(() => {
    fetchStudents();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Leave Request">
      <Container maxWidth="xl">
        <>
        <div
            className="d-flex"
            style={{
              flexDirection: "row",
              alignContent: "flex-end",
              justifyContent: "space-between",
              marginTop: "30px",
              marginBottom: "10px",
            }}
          >
            {/* <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton> */}


            {/* <Typography variant="h4">Vouchers</Typography> */}
            <h4>Leave Requests</h4>

            <div>  <Button
                onClick={handleNav}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Leave
              </Button></div>
          </div>

          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4.5}
            mt={2}
          >
            <Typography variant="h4">Leave Requests</Typography>
            <div>
              <Button
                onClick={handleNav}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Leave
              </Button>
            </div>
          </Stack> */}

          {students.length > 0 ? (
            <Card
              sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
            >
              {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

              <Scrollbar>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ padding: "10px" }}
                >
                  {/* <TextField
                    label="Search"
                    value={searchText}
                    onChange={handleInputChange}
                  /> */}
                  {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
                  {/* <Button variant="contained" onClick={handleReset}>
                    Reset
                  </Button> */}

                  {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
                </Stack>

                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <StudentListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={students.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {filteredStudents
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            _id,
                            student,
                            status,
                            leave_reason,
                            leave_date,
                            rejection_reason,
                          } = row;
                          const isItemSelected =
                            selected.indexOf(student.name) !== -1;
                          // let number = index + 1;

                          return (
                            <TableRow
                              // hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {index + 1}
                              </TableCell>

                              <TableCell align="left">
                                <div
                                  className="on_click_text"
                                  // style={{
                                  //   cursor: "pointer",
                                  // }}
                                  onClick={() => handleOpenModal(row)}
                                >
                                  {capitalizeFirstLetter(student.name)}
                                </div>
                              </TableCell>

                              {/* <TableCell align="left" style={myStyle}>
                                <RouterLink
                                  to={`/attendance/detail/${row._id}`}
                                  // to={`/attendance/calendar/${row.student._id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                  // className="on_click_text" // Add the class name you want here
                                >
                                  <div className="on_click_text">
                                    {capitalizeFirstLetter(title)}
                                  </div>
                                </RouterLink>
                              </TableCell> */}

                              {/* <TableCell align="left">
                                {capitalizeFirstLetter(batch.name)}
                              </TableCell> */}

                              <TableCell align="left" style={myStyle}>
                                {formatDateWithDay(leave_date)}
                              </TableCell>

                              {/* <TableCell align="left" style={myStyle}>
                                {formatDateWithDay(dead_line)}
                              </TableCell> */}

                              {/* <TableCell align="left">{total_marks}</TableCell> */}

                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    (status === 1 && "warning") ||
                                    (status === 2 && "success") ||
                                    (status === 3 && "error")
                                  }
                                >
                                  {status === 1 && "Pending"}
                                  {status === 2 && "Approved"}
                                  {status === 3 && "Rejected"}
                                </Label>
                              </TableCell>

                              <TableCell align="right">
                                {row.status === 1 ? (
                                  <StudentMoreMenu
                                    row={row}
                                    students={students}
                                    onStudentsUpdate={handleStudentsUpdate}
                                    trashStudentCount={trashStudentCount}
                                    setTrashStudentCount={setTrashStudentCount}
                                  />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  150,
                  { value: students.length, label: "All" },
                ]}
                component="div"
                count={students.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          ) : (
            <Card
              sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
            >
              <div className="my-5 py-5">
                <DataNotFoundCustomMessage
                  Heading={
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          "<span class='text-primary'> Leave Request Not Found! </span>",
                      }}
                    />
                  }
                  SubHeading={`Your Leave Requests are up to date. No Leave Request found.`}
                />
              </div>
            </Card>
          )}
        </>
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}

            // filterState={filterState}
            // setFilterState={setFilterState}
            // taskListData={taskListData}
            // setTaskListData={setTaskListData}
            // messageText={messageText}
            // setMessageText={setMessageText}
            // setDrawerState={setDrawerState}
          />
        }
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Leave Request Detail"
        componentToPassDown={
          <div className="mt-5">
            {/* <h1>hello</h1>
            <h1>hello</h1>
            <h1>hello</h1> */}
            <LeaveRequestDetail selectedRowStudent={selectedRowStudent} />
          </div>
        }
      />
    </Page>
  );
}
