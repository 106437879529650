import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import { Card, Typography, Grid, Badge, Chip } from "@mui/material";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import {
  compareDateWithTodayDate,
  formatDateWithDay,
} from "src/utils/formatTime";
import Label from "../Label";
import { useState } from "react";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(0, 0),
  borderRadius: "15px",
  padding: "5px 0px",
}));

const IconWrapperStyle = styled("div")(({ theme, Color }) => ({
  margin: "auto",
  marginTop: "0px",
  display: "flex",
  borderRadius: "25%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  //   marginBottom: theme.spacing(3),
  backgroundColor: "#f2f3f5",
  //   marginTop: "-3px",
  color: Color,
}));

export default function CustomCardFullLengthVoucher({
  voucher,
  Paid,
  Pending,
  Number,
  Fee,
  Title,
  className,
  Icon,
  voucherStatus,
  voucherType,
  dueDate,
  handleCardClick,
}) {
  const [lastDate, setlastDate] = useState("");
  useEffect(() => {
    if (
      voucher.voucher_status === 2 &&
      voucher.paid_amount &&
      voucher.paid_amount.length > 0
    ) {
      const lastPayment = voucher.paid_amount[voucher.paid_amount.length - 1];
      const lastPaymentDate = lastPayment.date;
      console.log("lastPaymentDate  __lastPaymentDate", lastPaymentDate);
      setlastDate(lastPaymentDate);
    } else {
      // console.log("Voucher status is not 2 or paid_amount is empty.");
    }
  }, []); // Make sure to include any dependencies if needed

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <div
          className={className}
          onClick={(event) => {
            console.log("Card clicked:");
            handleCardClick(voucher);
          }}
        >
          <div className="row mt-3">
            <div className="col-6 col-md-3  mb-3">
              {" "}
              <div className="views-lesson">
                <div className="batch_info_heading">Title</div>
                <div className="batch_info_sub_text">
                  {capitalizeFirstLetter(Title)}
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              {voucherStatus === 1 ? (
                <div className="views-lesson">
                  <div className="batch_info_heading">Total Fee</div>
                  <div className="batch_info_sub_text">{Fee} RS</div>
                </div>
              ) : (
                <div className="views-lesson">
                  <div className="batch_info_heading">Paid</div>
                  <div className="batch_info_sub_text">{Paid} RS</div>
                </div>
              )}
            </div>
            <div className="col-6 col-md-3 mb-3">
              <div className="views-lesson">
                <div className="batch_info_heading">Batch</div>
                <div className="batch_info_sub_text">
                  {capitalizeFirstLetter(voucher.batch.name)}
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 mb-3">
              {" "}
              <Chip
                label={formatDateWithDay(
                  lastDate && lastDate !== "" && voucherStatus == 2
                    ? lastDate
                    : voucher.voucher_refund_date &&
                      voucher.voucher_refund_date !== null &&
                      voucher.voucher_status == 4
                    ? voucher.voucher_refund_date
                    : dueDate
                )}
                color={
                  voucherStatus == 2
                    ? "success"
                    : voucherStatus == 4
                    ? "success"
                    // ? "primary"
                    : compareDateWithTodayDate(dueDate) == 1
                    ? "warning"
                    : compareDateWithTodayDate(dueDate) == 0
                    ? "error"
                    : "info"
                }
                sx={{
                  color:
                    voucherStatus == 2 || voucherStatus == 4 ? "white" : "",
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-md-3 mb-3">
              {voucherStatus == 3 ||
              voucherStatus == 2 ||
              voucherStatus == 4 ? (
                <div className="views-lesson">
                  <div className="batch_info_heading">Total Fee</div>
                  <div className="batch_info_sub_text">{Fee} RS</div>
                </div>
              ) : voucherStatus !== 1 ? (
                <div className="views-lesson">
                  <div className="batch_info_heading">Paid</div>
                  <div className="batch_info_sub_text">{Paid} RS</div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-6 col-md-3 mb-3">
              {voucherStatus == 3 ||
              voucherStatus == 2 ||
              voucherStatus == 4 ? (
                <div className="views-lesson">
                  <div className="batch_info_heading">Pending</div>
                  <div className="batch_info_sub_text">{Pending} RS</div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-6 col-md-3 mb-3">
              <Label
                variant="outlined"
                color={
                  ((voucherType === 1 || voucherType === 2) && "info") ||
                  "warning"
                }
              >
                {voucherType === 1
                  ? "Registration"
                  : voucherType === 2
                  ? // ? "Monthly"
                    "Fee Voucher"
                  : "Custom"}
              </Label>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={
                  <Chip
                    label={
                      voucherStatus === 1
                        ? "Pending"
                        : voucherStatus === 2
                        ? "Paid"
                        : voucherStatus === 4
                        ? "Refund"
                        : "Clearance"
                    }
                    color={
                      voucherStatus == 2
                        ? "success"
                        : voucherStatus == 4
                        ? "primary"
                        : voucherStatus == 1
                        ? "warning"
                        : voucherStatus == 2
                        ? "warning"
                        : "info"
                    }
                    sx={{
                      color:
                        voucherStatus == 2 || voucherStatus == 4 ? "white" : "",
                      padding: "0px!important",
                      height: "20px",
                    }}
                  />
                }
              >
                <IconWrapperStyle
                  Color={
                    voucherStatus == 1
                      ? "#ffc107" // yellow
                      : voucherStatus == 2
                      ? "Green"
                      : voucherStatus == 4
                      ? "#0a53b8" // yellow
                      : "#1890ff" //blue
                  }
                >
                  {Icon}
                </IconWrapperStyle>
              </Badge>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
}
