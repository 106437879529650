import React from "react";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import { Card, Typography, Grid, Badge, Chip } from "@mui/material";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import { compareDateWithTodayDate, formatDateWithDay } from "src/utils/formatTime";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(0, 0),
  borderRadius: "15px",
  padding: "5px 0px",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  marginTop: "0px",
  display: "flex",
  borderRadius: "25%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  backgroundColor: "#FFFFFF",
  marginTop: "-3px",
}));

export default function CustomCard({
  voucher,
  Fee,
  Title,
  className,
  Icon,
  voucherStatus,
  dueDate,
  handleCardClick,
}) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <RootStyle className={className}>
        <Grid
          container
          sx={{ pt: 0 }}
          // onClick={(event) => handleCardClick(event, voucher)}
          onClick={(event) => {
            console.log("Card clicked:");
            handleCardClick(voucher);
          }}
        >
          <Grid item xs={3} sx={{ pl: 1 }}></Grid>
          <Grid item xs={9} sx={{ color: "white" }}>
            <Chip
              label={formatDateWithDay(dueDate)}
              // color="primary"
              color={
                voucherStatus == 2
                  ? "success"
                  : compareDateWithTodayDate(dueDate) == 1
                  ? "warning"
                  : compareDateWithTodayDate(dueDate) == 0
                  ? "error"
                  : "primary"
              }
              //   color="info"
            />
          </Grid>

          <Grid item xs={3} sx={{ pl: 1 }}>
            <Badge
              badgeContent={
                voucherStatus == 1
                  ? "Pending"
                  : voucherStatus == 2
                  ? "Paid"
                  : "Due"
              }
              color={
                voucherStatus == 1
                  ? "warning"
                  : voucherStatus == 2
                  ? "success"
                  : "primary"
              }
            >
              <IconWrapperStyle>{Icon}</IconWrapperStyle>
            </Badge>
          </Grid>
          <Grid item xs={9} sx={{ color: "white", pt: 1 }}>
            <Typography variant="h5" sx={{ opacity: 1.0 }}>
              {Fee} RS
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 1.0 }}>
              {capitalizeFirstLetter(Title)}
            </Typography>
          </Grid>
        </Grid>
      </RootStyle>
    </Grid>
  );
}
