import React from "react";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import { Card, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(2, 0),
  borderRadius: "15px",
  cursor: "pointer", // Add cursor: pointer
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  marginTop: "10px",
  display: "flex",
  borderRadius: "25%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  backgroundColor: "#FFFFFF",
}));

export default function DashboardCard({ cards }) {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleCardClick = (path) => {
    navigate(path); // Use navigate to redirect to the specified path
  };

  return (
    <Grid container spacing={3}>
      {cards.map((card, index) => {
        return (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <RootStyle
              className={card.className}
              onClick={() => handleCardClick(card.path)}
            >
              <Grid container sx={{ pt: 1 }}>
                <Grid item xs={3} sx={{ pl: 1 }}>
                  <IconWrapperStyle>{card.icon}</IconWrapperStyle>
                </Grid>
                <Grid item xs={9} sx={{ color: "white" }}>
                  <Typography variant="h4">{card.counting}</Typography>
                  <Typography variant="subtitle2" sx={{ opacity: 0.9 }}>
                    {card.title}
                  </Typography>
                </Grid>
              </Grid>
            </RootStyle>
          </Grid>
        );
      })}
    </Grid>
  );
}
