import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Avatar,
  Badge,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Stack,
  Tooltip,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DateTimePicker } from "@mui/x-date-pickers";
import { _upload_attendance } from "src/DAL/attendance";
import { CircularLoader } from "src/components";
import { addImage } from "src/DAL/uploadfiles";
import {
  _detail_student_assignment,
  _upload_assignment_from_student,
} from "src/DAL/assignment";
import { _detail_leave_request_for_student } from "src/DAL/leave_requests";
import { s3baseUrl } from "src/config/config";
import TinyEditor from "src/components/Ckeditor";
import { htmlReturnFromCKEditor, truncateString } from "src/utils/formatText";
import {
  formatDateTimeWithOutZone,
  formatDateWithOutDay,
} from "src/utils/formatTime";
import CustomImageUploaderMulti from "src/components/GeneralComponents/CustomImageUploaderMulti";
import { getFileIcon } from "src/utils/formatOther";

const Input = styled("input")({
  display: "none",
});
function AssignmentSubmit() {
  const navigate = useNavigate();
  const moment = require("moment");
  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [allGames, setAllGames] = useState([]);
  const [allClubs, setAllClubs] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [acceptableFile, setAcceptableFile] = useState("");
  const [message, setMessage] = useState("");
  const [invoiceLink, setInvoiceLink] = useState("");
  const [studentLink, setStudentLink] = useState("");
  const [oldLinkURL, setoldLinkURL] = useState("");
  const [color, setColor] = useState("success");
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState([]);
  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;

  const urlParams = new URLSearchParams(window.location.search);
  let assignmentName = "";
  if (urlParams.get("assignment_name") != null) {
    assignmentName = urlParams.get("assignment_name");
  }

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    image: {},
  });

  const [oldImage, setOldImage] = useState("");
  const [detailDescriptionEditor, setDetailDescriptionEditor] = useState("");
  const [formInputs, setFormInputs] = useState({
    title: "",
    batch: {
      _id: "",
      name: "",
    },
    assigned_by: {
      _id: "",
      first_name: "",
      last_name: "",
    },
    // assigned_date: "2023-12-08T00:00:00.000Z",
    assigned_date: "",
    dead_line: "2023-12-08T00:00:00.000Z",
    total_marks: 0,
    status: true,
    submitted_by: [],
    question_paper_url: "",
    note: "",
  });

  const [inputs, setInputs] = useState({
    name: "",
    game_id: "",
    game: "",
    club_id: "",
    country: "Ireland",
    status: true,

    first_name: "",
    last_name: "",
    gender: "male",
    age: "",
    // dob: "",
    // height: "",
    // weight: "",
    // disability: "No",
    // skill: "",
  });

  const myStyleA = {
    textTransform: "capitalize",
    fontWeight: 600, // Add this line for bold font weight
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "center", // Add text alignment to center
  };

  const myStyle = {
    textTransform: "capitalize",
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "center", // Add text alignment to center
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function formatDate(inputDate) {
    // Parse the input date string
    const parsedDate = moment(inputDate);

    // Format the date as "Month Day, Year"
    const formattedDate = parsedDate.format("MMMM DD, YYYY");

    return formattedDate;
  }

  const fileChangedHandler = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.size <= 1 * 1024 * 1024) {
      // If the file is 5MB or smaller
      setImage(selectedFile);
      setPreviews(URL.createObjectURL(selectedFile));
      setAcceptableFile("true");
      setMessage("Acceptable File");
      setColor("success");
    } else if (selectedFile && selectedFile.size > 1 * 1024 * 1024) {
      // If the file is larger than 5MB
      setAcceptableFile("false");
      setMessage("Error: The file size exceeds the maximum allowed limit.");
      setColor("danger");
    }

    console.log("event", event);
    console.log("event.target.files[0]", selectedFile);
    console.log(
      "URL.createObjectURL(event.target.files[0])",
      URL.createObjectURL(selectedFile)
    );
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handle_format_data = (data) => {
    // const defaultDate = new Date(data.dob);
    console.log("handle_format_data", data);
    let clubID = "";
    let Gender = "male";
    if (data.club) {
      clubID = data.club._id;
    }

    if (data.gender === "Female" || data.gender === "female") {
      Gender = "female";
    }

    setInputs({
      ...data,
      game_id: data.game._id,
      club_id: clubID,
      gender: Gender,
    });

    setPreviews(data.image.src);
  };

  const get_detail_player = async () => {
    setIsLoading(true);
    const response = await _detail_student_assignment(_ID);

    console.log("response _detail_student_assignment", response);
    if (response.code === 200) {
      let AssignmentData = response.assignment;

      let newAssignmentData = {
        ...AssignmentData,
        title: AssignmentData.title,
        batch_name: AssignmentData.batch.name,
        // student: AssignmentData.student.name,
        student_name: AssignmentData.student.name,
        date: formatDate(AssignmentData.date),
        dead_line: formatDate(AssignmentData.assigned_date),
        assignment_status: AssignmentData.assignment_status,
        total_marks: AssignmentData.total_marks,
        obtained_marks:
          AssignmentData.obtained_marks === null
            ? ""
            : assignment.obtained_marks,
      };

      console.log(
        "response newAssignmentData _detail_student_assignment",
        newAssignmentData
      );
      setAssignment(newAssignmentData);

      //   handle_format_data(newGameData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inside");
    let error = "";
    const formData = new FormData();

    if (!detailDescriptionEditor || detailDescriptionEditor === "") {
      error = "Student Note is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    setIsLoading(true);

    const responseAssignment = await _upload_assignment_from_student(_ID, {
      // assignment_url: response.path,
      assignment_url: pathOfUploadedFile,
      student_note: detailDescriptionEditor,
    });

    console.log(
      "response _upload_assignment_from_student test",
      responseAssignment
    );
    if (responseAssignment.code === 200) {
      enqueueSnackbar(responseAssignment.message, { variant: "success" });
      navigate(-1);
  

      // Set timer to execute the code after 2 seconds
setTimeout(() => {
  // Your code to be executed after 2 seconds
  // For example:
  setIsLoading(false);
  // console.log('Code executed after 2 seconds');
}, 2000); // 2000 milliseconds = 2 seconds

    } else {
      enqueueSnackbar(responseAssignment.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const fetchDetailAssignment = async () => {
    setIsLoading(true);
    const response = await _detail_student_assignment(_ID);
    console.log("response _detail_student_assignment", response);
    if (response.code === 200) {
      
      const modifiedFiles = response.assignment.student.assignment_url.map(({ _id, ...rest }) => rest);
      setPathOfUploadedFile(modifiedFiles);

      setFormInputs({
        ...formInputs,
        ...response.assignment,
        // leave_date: defaultStartDate,
        // student_id: response.leave_request.student._id,
        // leave_reason: capitalizeFirstLetter(
        //   response.leave_request.leave_reason
        // ),
        // batch_id: batchIds, // Initialize as an empty array
      });

      setInvoiceLink(
        response.assignment.question_paper_url &&
          response.assignment.question_paper_url !== "" &&
          response.assignment.question_paper_url !== null
          ? response.assignment.question_paper_url
          : null
      );
      setDetailDescriptionEditor(response.assignment.student.student_note);
      setoldLinkURL(response.assignment.student.assignment_url);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailAssignment();
  }, []);

  const handleDownloadClick = () => {
    // Use the "click" method to trigger the download
    const link = document.createElement("a");
    link.href = invoiceLink;
    link.download = "downloaded-file"; // Set the desired file name
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Recommended for security reasons when using target="_blank"
    link.click();
  };

  const handleBadgeClick = (event) => {
    // Stop the propagation of the click event to prevent it from reaching the parent
    event.stopPropagation();
    handleDownloadClick();
  };

  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevents the default behavior (opening the link)

    // You can add any specific functionality you want when the link is clicked
    // For example, redirecting to the download URL or opening it in a new tab
    window.open(s3baseUrl + oldLinkURL, "_blank");
  };

  //===================================================================
  if (isLoading) {
    return <CircularLoader color="green" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <p className="">
            <h4 className="Assignment_heading">Assignment Submit </h4>
          </p>
        </div>
      </div>

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          // mb: 2,
          width: "100%",
          p: 4,
          pt: 4,
        }}
      >
        {/* <div className=" ">
          <div className="row">
            <div className="col-12 mb-0">
              <p>
                <h5 style={{ fontWeight: 600 }}>Assignment Detail</h5>{" "}
              </p>
            </div>
            <div className="col-12 col-md-3">
              <div className="views-lesson">
                <div className="batch_info_heading">Assignment Title</div>
                <div className="batch_info_sub_text">
                  {capitalizeFirstLetter(formInputs.title)}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="views-lesson">
                <div className="batch_info_heading">Batch</div>
                <div className="batch_info_sub_text">
                  {capitalizeFirstLetter(formInputs.batch.name)}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="views-lesson">
                <div className="batch_info_heading">Dead Line</div>
                <div className="batch_info_sub_text">
               
                  {formatDateWithOutDay(formInputs.assigned_date)}{" "}
                  {formInputs.assigned_date !== "" ? `(11:59 PM)` : ""}
                </div>
              </div>
            </div>

            <div
              className={`col-12 col-sm-12 mt-4`}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div className="views-lesson">
                <div className="batch_info_heading">Attached Files</div>
                <div className="batch_info_sub_text">
                <div
          className={`col-12 col-sm-12 mt-4`}
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {invoiceLink.map((filePath, index) => (
            <Box
              key={index}
              position="relative"
              display="inline-block"
              border="2px solid #ccc"
              p={1}
              mb={2} // Add margin bottom for spacing between files
              style={{ marginRight: "10px" }} // Adjust spacing between files
            >
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <a
                  href={s3baseUrl + filePath.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getFileIcon(filePath.name, filePath.path)}
                </a>
              </Box>

              <Box>
                ({index + 1}){" "}
                <a
                  href={s3baseUrl + filePath.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      filePath.name.length > 13
                        ? capitalizeFirstLetter(filePath.name)
                        : ""
                    }
                    arrow
                  >
                    {capitalizeFirstLetter(truncateString(filePath.name, 13))}
                  </Tooltip>
                </a> 
                &nbsp;&nbsp;
              </Box>
          
            </Box>
          ))}
        </div>
                   
                </div>
              </div>
            </div>

            {formInputs.assignment_note !== "" &&
            formInputs.assignment_note !== null ? (
              <div className="col-12 mt-5 mb-0">
                <p>
                  <h5 style={{ fontWeight: 600 }}>Assignment Note</h5>{" "}
                  <div className="px-2">
                    {htmlReturnFromCKEditor(formInputs.assignment_note)}
                  </div>
                </p>
              </div>
            ) : (
              <div className=" mb-3"></div>
            )}
          </div>
        </div>
        <hr /> */}
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <CustomImageUploaderMulti
              pathOfUploadedFile={pathOfUploadedFile}
              setPathOfUploadedFile={setPathOfUploadedFile}
              imageFileHeading={"Student Assignment File"}
              imageFileSubHeading={"Acceptable File Size: 1MB or below Supported Formats:  .rar, .zip, .pdf, .doc, .docx"}
              imageFileLimitSizeUpload={1}
              imageFileButtonText={"Upload"}
            />

            {/* <div className="col-12">
              <div className="row">
                <div className="col-5">
                  <p className="Student_submit_label_heading">
                    Student Assignment File{" "}
                  </p>
                  {oldLinkURL !== null &&
                  oldLinkURL !== "" &&
                  acceptableFile !== "true" ? (
                    <>
                      {" "}
                      (
                      <a
                        href={s3baseUrl + oldLinkURL}
                        target="_blank" // Opens the link in a new tab/window
                        rel="noopener noreferrer" // Recommended for security reasons when using target="_blank"
                        onClick={handleLinkClick}
                      >
                        Click here to download
                      </a>
                      )
                    </>
                  ) : (
                    ""
                  )}

                  <FormHelperText className="pt-0">
                    Acceptable File Size: 1MB or below Supported Formats: ".rar,
                    .zip, .pdf, .doc, .docx"
                  </FormHelperText>
                </div>

                <div className={`col-4 mt-4`}>
   
                  {acceptableFile === "true" ? (
                    <>
                      <Icon
                        icon={
                          "streamline:interface-validation-check-circle-checkmark-addition-circle-success-check-validation-add-form"
                        }
                        width={40}
                        height={40}
                        style={{ color: "green" }} // Set the text color to green
                      />
                      <span style={{ color: "green", paddingLeft: "5px" }}>
                        {message}
                      </span>
                    </>
                  ) : acceptableFile === "false" ? (
                    <>
                      <Icon
                        icon={"system-uicons:cross-circle"}
                        width={40}
                        height={40}
                        style={{ color: "red" }} // Set the text color to red
                      />
                      <span style={{ color: "red", paddingLeft: "5px" }}>
                        {message}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-3 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      // accept="image/*"
                      accept=".rar,.zip,.pdf,.doc,.docx"
                      //   accept=".csv"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Button
                      id="contained-button-file"
                      className="small-contained-button"
                      color={
                        acceptableFile === "true"
                          ? "success"
                          : acceptableFile === "false"
                          ? "error"
                          : "info"
                      }
                      startIcon={<FileUploadIcon />}
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
            </div> */}

            <div className="col-12 mt-1 mb-4">
              <p className="Student_submit_label_heading">Student Note *</p>

              <TinyEditor
                detailDescriptionCk={detailDescriptionEditor}
                setDetailDescription={setDetailDescriptionEditor}
              />
            </div>

            <div className="col-12 text-end">
              {/* <Button
                disabled={acceptableFile === "true" ? false : true}
                id="contained-button-file"
                className="small-contained-button"
                component="span"
                type="submit"
              >
                Submit
              </Button> */}

              <button
                className={`btn btn-outline-primary ${
                  acceptableFile === "true" || acceptableFile === ""
                    ? ""
                    : "disabled"
                } `}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default AssignmentSubmit;
