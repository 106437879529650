import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Link, Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../components/Logo";
// components
import { AuthSocial, Page } from "src/components";

//files
import { LoginImg } from "src/assets";
import { LoginForm} from ".";
import LoginFormAsAdmin from "./components/LoginFormAsAdmin";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginAdmin() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Login | Student Portal">
      <RootStyle>
        <HeaderStyle>
          <Logo />

          {/* {smUp && (
            <Typography variant='body2' sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant='subtitle2' component={RouterLink} to='/register'>
                Get started
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={LoginImg} alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
          

            {/* <AuthSocial /> */}

            <LoginFormAsAdmin />

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{" "}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )} */}
               {/* <Typography variant="body2" align="center" sx={{ mt: 3 }}>
   
                Sign in As &nbsp;
                <Link variant="subtitle2" component={RouterLink} to="/login">
                   Admin
                </Link>
                {" "}&nbsp;{" "}
                <Link variant="subtitle2" component={RouterLink} to="/login-customer">
                   Customer
                </Link>
              </Typography> */}
              
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
