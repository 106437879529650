import React from "react";

import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAppContext } from "src/hooks";
import ClearIcon from "@mui/icons-material/Clear";
// material
import {
  Avatar,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
  Badge,
  Icon,
  FormHelperText,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";

import {
  _student_detail_with_token,
  _update_student,
  detailAdmin,
} from "src/DAL/auth";
import { s3baseUrl } from "src/config/config";
import {
  _detail_customer_admin,
  _detail_customer_without_token,
  _instructor_detail_with_token,
} from "src/DAL/customer";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { default_avatar_1 } from "src/assets";
import { _upload_image_s3, addImage } from "src/DAL/uploadfiles";
import { delImage } from "src/DAL/delfiles";
import CustomDateInput from "src/components/GeneralComponents/CustomDateInput";
const Edit = () => {
  const theme = useTheme();
  const { _set_user_profile } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [admin, setAdmin] = useState();
  const [path, setPath] = useState();
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [show, setShow] = useState(false);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [date, setDate] = useState("");
  const [datePost, setDatePost] = useState("");
  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = () => {
    // You can perform any necessary actions here before navigating
    // For example, submitting data, handling loading, etc.
    // After that, navigate to the desired route.
    navigate("/profile/edit-profile");
  };

  const Input = styled("input")({
    display: "none",
  });

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    profile_image: "",
    last_name: "",
    name: "",
    father_name: "",
    email: "",
    address: "",
    date_of_birth: "",
    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    cnic: "",
    identity_card_number: "",
  });

  const uploadImage = async (img) => {
    // console.log("hello");
    // console.log(img);
    setIsLoadingButton(true);
    const data = new FormData();
    // data.append("file", img);
    data.append("image", img);
    const result = await _upload_image_s3(data);
    console.log(
      "result ___result  _upload_image_s3 ___upload_image_s3",
      result
    );
    if (result.code === 200) {
      console.log("Uploaded");
      //handleLoading(index, true);

      // console.log(res,"--data")
      // console.log("path", result.path);
      setPath(result.path);
      setIsLoadingButton(false);
    } else {
      console.log(`Error: ${result.status}`);
      setIsLoadingButton(false);
    }
  };

  const handleDeleteImage = (event) => {
    // Stop the propagation of the click event
    event.stopPropagation();
    // imageDelete(path);
    // setImage("");
    // // console.log(image);
    // setShow(false);

    console.log("image  ___image",image);
    console.log("path  ___image",path);
    console.log("formInputs.profile_image  ___image",formInputs.profile_image);

    if(!path || path===null || path===""){
      imageDelete(formInputs.profile_image);
    }else{
      imageDelete(path);
    }

    // imageDelete(path);
    setImage("");
    setPreviews("");
    // console.log(image);
    setFormInputs({ ...formInputs, profile_image: "" });
    setShow(false);
    // setPath("");
  };
  const imageDelete = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    setIsLoadingButton(true);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      setPath("");
      //handleLoading(index, true);
      setIsLoadingButton(false);
      // console.log(res,"--data")
    } else {
      setIsLoadingButton(false);
      console.log(`Error: ${result.status}`);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inside");
    const storedObjectString = localStorage.getItem("user");
    if (storedObjectString) {
      const storedUser = JSON.parse(storedObjectString);

      // const data = new FormData();
      // data.append("first_name", formInputs.name);
      // data.append("last_name", formInputs.father_name);
      // data.append("email", formInputs.email);
      // data.append("address", formInputs.address);
      // if (path) {
      //   data.append("profile_image", path);
      // } else {
      //   data.append("profile_image", image);
      // }
      let postDate = datePost;
      if (datePost == "Invalid Date") {
        postDate = "";
      }
      setIsLoadingButton(true);
      let postData = {
        name: capitalizeFirstLetter(formInputs.name),
        father_name: capitalizeFirstLetter(formInputs.father_name),
        email: formInputs.email,
        address: capitalizeFirstLetter(formInputs.address),
        // image: path ? path : image,
        // image: path ? path : (image ? image : ""), // Set image to empty string if path and image are falsy
        image: path ? path : (typeof image === 'object' ? "" : image), // Set image to empty string if path and image are falsy

        latest_degree: capitalizeFirstLetter(formInputs.latest_degree),
        degree_status: formInputs.degree_status,
        completion_year: `${selectedYear}`,
        institution_name: capitalizeFirstLetter(formInputs.institution_name),
        semester: capitalizeFirstLetter(formInputs.semester),
        cnic: formInputs.identity_card_number,
        date_of_birth: postDate,
      };

      console.log("result __postData postData", postData);
      console.log("result __postData path", path);
      console.log("result __postData image", image);
      // console.log("result __postData previewUrl", previewUrl);

      const result = await _update_student(postData);
      console.log("result _update_student _update_student", result);
      if (result.code === 200) {
        const token = localStorage.getItem("user");
        const payload = JSON.parse(token);
        // console.log(payload.email);
        const user = {
          first_name: result.student.name,
          last_name: result.student.father_name,
          image: result.student.image,
          email: payload.email,
          address: result.address,
          // type: 2,
          // privilege: formInputs.role.privilege,
        };

        // console.log(JSON.stringify(user));
        localStorage.setItem("user", JSON.stringify(user));
        const storedObjectString = localStorage.getItem("user");
        const storedObject = JSON.parse(storedObjectString);
        _set_user_profile(storedObject);
        setIsLoadingButton(false);
        navigate("/dashboard");
        enqueueSnackbar("Edited Successfully!", { variant: "success" });
      } else {
        setIsLoadingButton(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleCNICChange = (e) => {
    const { name, value } = e.target;
    // console.log("here value", value);

    // Remove all non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 13); // Limit input to 13 characters

    // Insert hyphens at the appropriate positions
    let formattedCNIC = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += "-";
      }
      formattedCNIC += numericValue[i];
    }

    setFormInputs({ ...formInputs, [name]: formattedCNIC });
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  function handleFile(e) {
    // // console.log(e.target.files);
    // const file = e.target.files[0];
    // // console.log(file);
    // setImage(file);
    // // console.log(image);
    // setPreviewUrl(URL.createObjectURL(file));
    // setShow(true);
    // uploadImage(file);
  }

  const fileChangedHandler = (e) => {
    e.stopPropagation();
    //   setImage(e.target.files[0]);
    // setPreviews(URL.createObjectURL(e.target.files[0]));

    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);

    setPreviews(URL.createObjectURL(file));
    setShow(true);
    uploadImage(file);
  };
  // const fileChangedHandler = (event) => {
  //   setImage(event.target.files[0]);
  //   setPreviews(URL.createObjectURL(event.target.files[0]));
  // };

  const fetchProfile = async () => {
    // Check if "user" is in localStorage and parse it
    // const storedObjectString = localStorage.getItem("user");
    // if (storedObjectString) {
    // const storedUser = JSON.parse(storedObjectString);

    const response = await _student_detail_with_token();
    console.log("response _student_detail_with_token", response);
    if (response.code === 200) {
      setIsLoading(false);
      setAdmin(response.student);
      setDate(response.student.date_of_birth);

      console.log("response __response detail", response);
      setFormInputs({
        ...response.student,
        first_name: capitalizeFirstLetter(response.student.name),
        last_name: capitalizeFirstLetter(response.student.father_name),
        profile_image: response.student.image,
        identity_card_number: response.student.cnic,
        _id: response.student._id,
        // contact_number: response.customer.contact_number,
        // post_code: response.customer.post_code,
        // role_id: response.customer.role._id,
      });
      if (response.student.image) {
        setImage(response.student.image);
        setPreviews(s3baseUrl + response.student.image);
      }

      const year = response.student.completion_year;

      if (typeof year === "string") {
        // If it's a string, convert it to a number
        setSelectedYear(parseInt(year));
      } else if (typeof year === "number") {
        // If it's already a number, set it directly
        setSelectedYear(year);
      } else {
        // Handle other types or null/undefined values
        // console.error('Invalid year format:', year);
      }
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Profile Edit">
      <div className="container">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // marginTop: "10px",
            // marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          {/* <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Profile
          </Typography> */}
        </Grid>

        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            mt: 1,
            width: "100%",
            p: 4,
          }}
        >
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row table-form">
              <div className="col-12 mt-4 mb-5">
                <div className="row mb-1">
                  <div className="col-12 text-center">
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        name="image"
                        onChange={fileChangedHandler}
                      />

                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <>
                            <span
                              style={{
                                border: "1px solid #dbdbdb",
                                borderRadius: "50%",
                                // background: "white",
                                background: "transparent",
                                display: "inline-block",
                                padding: "5px",
                                // color: "#00ab55",
                                color: "#0c2e60",
                                cursor: "pointer", // Add this line to set the cursor to pointer
                              }}
                            >
                              <Iconify
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  padding: "5px",
                                }}
                                icon="fluent:camera-add-20-filled"
                                // icon="uiw:edit"
                                width={20}
                                height={20}
                              />

                              {/* <Icon 
                              style={{
                                height: "40px",
                                width: "40px",
                                padding: "5px",
                              }}
                              icon="fluent:camera-add-20-filled"
                              width={20}
                              height={20}
                            /> */}
                            </span>
                          </>
                        }
                      >
                        <Avatar
                          id="contained-button-file"
                          // src={image ?? (previews ?? default_avatar_1)}
                          // src={previews!==""&&previews!==null? previews : (image!==""&&previews!==null? `${s3baseUrl}${image}` : default_avatar_1)}
                          // src={(previews !== "" && previews !== null ? previews : (image !== "" && image !== null ? `${s3baseUrl}${image}` : default_avatar_1))}
                          src={previews ? previews : default_avatar_1}
                          // src={
                          //   image?image:
                          //   ?previews:previews:default_avatar_1

                          //   // `${previews}`|| default_avatar_1
                          //   // `${s3baseUrl}${image}`|| default_avatar_1
                          //   // `${s3baseUrl}uploads/assets/profile-icon.png`
                          // }
                          className="avatar"
                          alt="Avatar"
                          style={{
                            width: "150px",
                            height: "150px",
                            border: "1px solid #dbdbdb",
                            cursor: "pointer", // Add this line to set the cursor to pointer
                          }} // Adjust the size as desired
                        />
                        {previews ? (
                          <IconButton
                            aria-label="delete"
                            // onClick={() => handleDeleteImage()}
                            onClick={(event) => handleDeleteImage(event)}
                            style={{
                              position: "absolute",
                              top: -10,
                              right: -10,
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </Badge>
                    </label>
                    {/* <FormHelperText className="pt-3 text-center">
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText> */}
                  </div>
                </div>
              </div>
              {/* Here form input code */}

              <Stack spacing={2}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={formInputs.name}
                    onChange={handleChange}
                    name="name"
                    required
                    // inputProps={{
                    //   pattern: "[A-Za-z]{3,}",
                    //   title:
                    //     "Please enter at least 3 letters with only alphabetical characters.",
                    // }}
                  />
                  <TextField
                    fullWidth
                    label="Father Name"
                    value={formInputs.father_name}
                    onChange={handleChange}
                    name="father_name"
                    // required
                  />
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={formInputs.email}
                    onChange={handleChange}
                    name="email"
                    // required
                    // inputProps={{
                    //   pattern: "[A-Za-z]{3,}",
                    //   title:
                    //     "Please enter at least 3 letters with only alphabetical characters.",
                    // }}
                  />
                  <TextField
                    fullWidth
                    label="Address"
                    value={formInputs.address}
                    onChange={handleChange}
                    name="address"
                    // required
                    // inputProps={{
                    //   pattern: "[0-9]*", // Only allow numeric characters
                    //   // maxLength: 10, // Limit the input to 10 characters
                    //   // title: "Please enter a valid 10-digit phone number",
                    // }}
                    // onKeyPress={(event) => {
                    //   if (!/[0-9]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                  />
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Latest Degree"
                    value={formInputs.latest_degree}
                    onChange={handleChange}
                    name="latest_degree"
                  />

                  <TextField
                    fullWidth
                    label="Degree Status"
                    select
                    value={formInputs.degree_status}
                    onChange={handleChange}
                    name="degree_status"
                  >
                    <MenuItem value="complete">Complete</MenuItem>
                    <MenuItem value="inprogress">Inprogress</MenuItem>
                  </TextField>
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  {formInputs.degree_status === "complete" ? (
                    <TextField
                      fullWidth
                      label="Completion Year"
                      select
                      value={selectedYear}
                      onChange={handleYearChange}
                      name="degree_status"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    ""
                  )}

                  <TextField
                    fullWidth
                    label="Institution Name"
                    value={formInputs.institution_name}
                    onChange={handleChange}
                    name="institution_name"
                  />
                  {formInputs.degree_status === "inprogress" ? (
                    <TextField
                      fullWidth
                      label="Semester"
                      value={formInputs.semester}
                      onChange={handleChange}
                      name="semester"
                    />
                  ) : (
                    ""
                  )}
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  {/* <TextField
                    fullWidth
                    label="CNIC"
                    value={formInputs.cnic}
                    onChange={handleChange}
                    name="cnic"
                  /> */}
                  <TextField
                    fullWidth
                    label="CNIC"
                    value={formInputs.identity_card_number}
                    onChange={handleCNICChange}
                    name="identity_card_number"
                    autoComplete="off"
                    inputProps={{
                      pattern: "[0-9]{5}-[0-9]{7}-[0-9]{1}",
                      title:
                        "Please enter a valid cnic number in the format XXXXX-XXXXXXX-X",
                    }}
                  />

                  <CustomDateInput
                    date={date}
                    setDate={setDate}
                    datePost={datePost}
                    setDatePost={setDatePost}
                    labet="Date Of Birth"
                  />
                </Stack>

                <Stack alignItems="end">
                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    loading={isLoadingButton}
                    sx={{ width: "120px" }}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>
            </div>
          </form>
        </Card>
      </div>
    </Page>
  );
};

export default Edit;
