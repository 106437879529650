import { replace } from "lodash";
import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

// Function to calculate the total paid amount
export function calculatePaidAmount(voucher) {
  if (voucher?.paid_amount) {
    return voucher.paid_amount.reduce((sum, item) => sum + item.amount, 0);
  } else {
    return 0;
  }
}

// Function to calculate the pending amount
export function calculatePendingAmount(voucher) {
  if (voucher?.paid_amount) {
    if (voucher.voucher_type === 1) {
      return (
        voucher.fee - calculatePaidAmount(voucher) - voucher.additional_discount
      );
    } else if (voucher.voucher_type === 2) {
      return (
        voucher.fee -
        calculatePaidAmount(voucher) -
        voucher.fee_discount -
        voucher.additional_discount
      );
    } else if (voucher.voucher_type === 3) {
      return (
        voucher.fee - calculatePaidAmount(voucher) - voucher.additional_discount
      );
    }
  } else {
    return 0;
  }
}


// Function to calculate the discount amount
export function calculateDiscountAmount(voucher) {
   
    if (voucher?.voucher_type === 1) {
      return voucher.additional_discount;
    } else if (voucher?.voucher_type === 2) {
      return    voucher.fee_discount + voucher.additional_discount;
    } else if (voucher?.voucher_type === 3) {
      return voucher.additional_discount ;
    }else{
      return 0;
    }
   
}