import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import {
  Card,
  Typography,
  Grid,
  Badge,
  Chip,
  Box,
  Avatar,
} from "@mui/material";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import {
  compareDateWithTodayDate,
  formatDateWithDay,
} from "src/utils/formatTime";
import Label from "../Label";
import { useState } from "react";
import Iconify from "../Iconify";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(0, 0),
  borderRadius: "15px",
  padding: "5px 0px",
}));

const IconWrapperStyle = styled("div")(({ theme, Color }) => ({
  margin: "auto",
  marginTop: "0px",
  display: "flex",
  borderRadius: "25%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  //   marginBottom: theme.spacing(3),
  backgroundColor: "#f2f3f5",
  //   marginTop: "-3px",
  color: Color,
}));

export default function CustomCardFullLengthVoucherNewDesign({
  voucher,
  Paid,
  Pending,
  Number,
  Fee,
  Title,
  className,
  Icon,
  voucherStatus,
  voucherType,
  dueDate,
  handleCardClick,
}) {
  const [lastDate, setlastDate] = useState("");
  useEffect(() => {
    if (
      voucher.voucher_status === 2 &&
      voucher.paid_amount &&
      voucher.paid_amount.length > 0
    ) {
      const lastPayment = voucher.paid_amount[voucher.paid_amount.length - 1];
      const lastPaymentDate = lastPayment.date;
      console.log("lastPaymentDate  __lastPaymentDate", lastPaymentDate);
      setlastDate(lastPaymentDate);
    } else {
      // console.log("Voucher status is not 2 or paid_amount is empty.");
    }
  }, []); // Make sure to include any dependencies if needed

  return (
    <Card
      onClick={(event) => {
        console.log("Card clicked:");
        handleCardClick(voucher);
      }}
      sx={{ cursor: "pointer" }}
    >
      <div className="row p-2 pt-3 pb-2">
        {/* First */}
        <div className="col-12 col-md-3 mb-2">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={"tabler:file-dollar"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                // color: iconTextColor,
                // backgroundImage: `linear-gradient(135deg, ${iconBGColor} 0%, ${iconBGColor} 100%)`,
                color: "#fbb03c",
                backgroundImage: `linear-gradient(135deg, #fff3e0 0%, #fff3e0 100%)`,
              }}
            >
              <Iconify icon={"tabler:file-dollar"} width={24} height={24} />
            </Avatar>

            <Box sx={{ marginLeft: "10px" }}>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Title
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {capitalizeFirstLetter(Title)}
              </Typography>
            </Box>
          </Box>
        </div>
        {/* Second */}
        <div className="col-12 col-md-3 mb-2">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={"row.user.name"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                color: "#fd7771",
                backgroundImage: `linear-gradient(135deg, #ffe8e7 0%, #ffe8e7 100%)`,
              }}
            >
              <Iconify icon={"ph:student-bold"} width={20} height={20} />
            </Avatar>

            <Box sx={{ marginLeft: "10px" }}>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Batch
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {capitalizeFirstLetter(voucher.batch.name)}
              </Typography>
            </Box>
          </Box>
        </div>
        {/* Third */}
        <div className="col-12 col-md-3 mb-2">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={"row.user.name"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                color: "#9561ff",
                backgroundImage: `linear-gradient(135deg, #efe7ff 0%, #efe7ff 100%)`,
              }}
            >
              <Iconify icon={"carbon:time"} width={20} height={20} />
            </Avatar>

            <Box sx={{ marginLeft: "10px" }}>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Date
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {formatDateWithDay(
                  lastDate && lastDate !== "" && voucherStatus == 2
                    ? lastDate
                    : voucher.voucher_refund_date &&
                      voucher.voucher_refund_date !== null &&
                      voucher.voucher_status == 4
                    ? voucher.voucher_refund_date
                    : dueDate
                )}
              </Typography>
            </Box>
          </Box>
        </div>
        {/* Forth */}
        <div className="col-12 col-md-3 mb-2">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt={"fluent-emoji-flat:dollar-banknote"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "-20px",
                color: "#4070ed",
                backgroundImage: `linear-gradient(135deg, #e7edff 0%, #e7edff 100%)`,
              }}
            >
              <Iconify
                icon={"fluent-emoji-flat:dollar-banknote"}
                sx={{ marginTop: "-5px" }}
                width={24}
                height={24}
              />
            </Avatar>

            <Box sx={{ marginLeft: "10px" }}>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                {voucherStatus === 1 ? `Total Fee` : `Total Fee`}
                {/* {voucherStatus === 1 ? `Total Fee`
                : `Paid`} */}
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {/* {voucher.student.result_status=== 3 || voucher.student.result_status===2   ? (
              <Label
                variant="outlined"
                color={
                  voucher.student.result_status === 3 && voucherStatus ===3 ? "error" : 
                  voucher.student.result_status === 2 && voucherStatus ===3 ? "success" : 
                  "warning"}
              >
                {`${
                  voucher.student.result_status === 3 && voucherStatus ===3 ? "Failed" :
                  voucher.student.result_status === 2 && voucherStatus ===3 ? "Pass" :
                  "Pending"
                }`}
              </Label>
            ) : (
              ""
            )} */}
                {/* <span style={{fontWeight:500}}> 
              {voucher.student.obtained_marks !== null && voucherStatus ===3
                ? `    ${voucher.student.percentage}% `
                : ``}
                </span> */}

                {/* {voucherStatus === 1 ? `${Fee} RS` :`${Paid} RS`} */}
                {voucherStatus === 1 ? `${Fee} RS` : `${Fee} RS`}
              </Typography>
            </Box>
          </Box>
        </div>

        {/* Second Row */}
        <>
          {voucherStatus !== 1 ? (
            <>
              {/* Second First */}
              <div className="col-12 col-md-3 mb-2">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    alt={"mdi:cash-check"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "-20px",
                      color: "#27cd21",
                      backgroundImage: `linear-gradient(135deg, #e8ffe7 0%, #e8ffe7 100%)`,
                    }}
                  >
 

 

                    <Iconify
                      icon={"mdi:cash-check"}
                    //   sx={{ marginTop: "-5px" }}
                      width={24}
                      height={24}
                    />
                  </Avatar>

                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography variant="body1" sx={{ fontWeight: "600" }}>
                      {`Paid`}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      {`${Paid} RS`}
                    </Typography>
                  </Box>
                </Box>
              </div>

              {/* Second Second */}
              <div className="col-12 col-md-3 mb-2">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    alt={"mdi:cash-clock"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "-20px",
                      color: "#cdcd20",
                      backgroundImage: `linear-gradient(135deg, #e8ffe7 0%, #fff3e0 100%)`,
                    }}
                  >
                    <Iconify
                      icon={"mdi:cash-clock"}
                    //   sx={{ marginTop: "-5px" }}
                      width={24}
                      height={24}
                    />
                  </Avatar>

                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography variant="body1" sx={{ fontWeight: "600" }}>
                      {`Pending`}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      {`${Pending} RS`}
                    </Typography>
                  </Box>
                </Box>
              </div>

              {/* Second Third */}
              <div className="col-12 col-md-3 mb-2">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    alt={"icon-park-outline:file-question"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "-20px",
                      color: "#d060d0",
                      backgroundImage: `linear-gradient(135deg, #ffedff 0%, #e7edff 100%)`,
                    }}
                  >
                    <Iconify
                      icon={"icon-park-outline:file-question"}
                    //   sx={{ marginTop: "-5px" }}
                      width={22}
                      height={22}
                    />
                  </Avatar>

                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography variant="body1" sx={{ fontWeight: "600" }}>
                      {`Voucher Type`}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <Label
                        variant="outlined"
                        color={
                          ((voucherType === 1 || voucherType === 2) &&
                            "info") ||
                          "warning"
                        }
                      >
                        {voucherType === 1
                          ? "Registration"
                          : voucherType === 2
                          ? // ? "Monthly"
                            "Fee Voucher"
                          : "Custom"}
                      </Label>
                    </Typography>
                  </Box>
                </Box>
              </div>

              {/* Second Forth */}
              <div className="col-12 col-md-3 mb-2">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    alt={"majesticons:chat-status-line"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "-20px",
                      color: "#4070ed",
                      backgroundImage: `linear-gradient(135deg, #e7edff 0%, #e7edff 100%)`,
                    }}
                  >
                    <Iconify
                      icon={"majesticons:chat-status-line"}
                    //   sx={{ marginTop: "-5px" }}
                      width={22}
                      height={22}
                    />
                  </Avatar>

                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography variant="body1" sx={{ fontWeight: "600" }}>
                      {`Voucher Status`}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <Label
                        variant="outlined"
                        color={
                          voucherStatus === 1
                            ? "warning"
                            : voucherStatus === 2
                            ? "success"
                            : voucherStatus === 4
                            ? "info"
                            : "warning"
                        }
                      >
                        {voucherStatus === 1
                          ? "Pending"
                          : voucherStatus === 2
                          ? "Paid"
                          : voucherStatus === 4
                          ? "Refund"
                          : "Clearance"}
                      </Label>
                    </Typography>
                  </Box>
                </Box>
              </div>
            </>
          ) : null}
        </>
      </div>
    </Card>
  );
}
