import React, { useEffect, useState } from "react";
import { Label, Logo } from "src/components";
import {
  calculateDiscountAmount,
  calculatePaidAmount,
  calculatePendingAmount,
} from "src/utils/formatNumber";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { formatDateWithOutDay } from "src/utils/formatTime";

const VoucherInvoiceDetail = ({ obj }) => {
  console.log("obj   ____obj", obj);
  const [lastDate, setlastDate] = useState("");
  let currentYear = new Date().getFullYear();

  useEffect(() => {
    if (
      obj.voucher_status === 2 &&
      obj.paid_amount &&
      obj.paid_amount.length > 0
    ) {
      const lastPayment = obj.paid_amount[obj.paid_amount.length - 1];
      const lastPaymentDate = lastPayment.date;
      console.log("lastPaymentDate  __lastPaymentDate", lastPaymentDate);
      setlastDate(lastPaymentDate);
    } else {
      // console.log("Voucher status is not 2 or paid_amount is empty.");
    }
  }, [lastDate]); // Make sure to include any dependencies if needed

  return (
    <div className="mt-4">
      <div
        style={{ fontFamily: "Helvetica", margin: 0, padding: 0 }}
        className="invoice-container"
      >
        <div
          style={{
            backgroundColor: "#000033",
            color: "#fff",
            textAlign: "center",
            padding: "20px 10px",
          }}
          id="invoice-header"
        >
          {/* Invoice Details */}
        </div>

        <div
          style={{
            backgroundColor: "#ffb072",
            padding: "5px",
            textAlign: "center",
            color: "#000",
          }}
          id="invoice-subheader"
        >
          {/* Invoice Details */}
        </div>

        <div
          style={{
            margin: "40px auto",
            textAlign: "center",
            transform: "scale(0.8)",
          }}
          id="logo"
        >
          {/* <img src="src/utils/images/ddit_logo.png" alt="Logo" width="70" height="50" /> */}
          {/* <img src="http://localhost:3000/" alt="Logo" width="70" height="50" /> */}
          <Logo />
        </div>
        <div className="container col-11">
          <div className="container mt-4 mb-5 ">
            <div className="row ">
              <div className="col-12 col-md-6">
                <div className="row mb-1" style={{ fontSize: "14px" }}>
                  <div className="col-12">
                    <span style={{ fontWeight: 600 }}>Invoice Number: </span>
                    {obj._id}
                  </div>
                </div>

                <div className="row mb-1" style={{ fontSize: "14px" }}>
                  <div className="col-12">
                    <span style={{ fontWeight: 600 }}>Due Date: </span>
                    {formatDateWithOutDay(obj.due_date)}
                  </div>
                </div>

                {lastDate && lastDate !== "" && obj.voucher_status == 2 ? (
                  <div className="row mb-1" style={{ fontSize: "14px" }}>
                    <div className="col-12">
                      <span style={{ fontWeight: 600 }}>Paid Date: </span>
                      {formatDateWithOutDay(lastDate)}
                    </div>
                  </div>
                ) : (
                  ""
                )}

{obj.voucher_refund_date && obj.voucher_refund_date !== null && obj.voucher_status == 4 ? (
                  <div className="row mb-1" style={{ fontSize: "14px" }}>
                    <div className="col-12">
                      <span style={{ fontWeight: 600 }}>Refund Date: </span>
                      {formatDateWithOutDay(obj.voucher_refund_date)}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row mb-1" style={{ fontSize: "14px" }}>
                  <div className="col-12">
                    <span style={{ fontWeight: 600 }}>Batch: </span>{" "}
                    {capitalizeFirstLetter(obj.batch.name)}
                  </div>
                </div>

                {obj.voucher_type == 2 ? (
                  <div className="row mb-1" style={{ fontSize: "14px" }}>
                    <div className="col-12">
                      <span style={{ fontWeight: 600 }}>Course: </span>{" "}
                      {capitalizeFirstLetter(
                        obj.course ? obj.course.title : " - - "
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row mb-1" style={{ fontSize: "14px" }}>
                  <div className="col-12">
                    <span style={{ fontWeight: 600 }}>Status: </span>{" "}
                    <Label
                      variant="outlined"
                      color={
                        ((obj.voucher_status === 1 ||
                          obj.voucher_status === 3) &&
                          "warning") ||
                        (obj.voucher_status === 2 && "success") ||
                        "primary"
                      }
                    >
                      {obj.voucher_status === 1
                        ? "Pending"
                        : obj.voucher_status === 3
                        ? "Pending Clearance"
                        : obj.voucher_status === 2
                        ? "Paid"
                        : obj.voucher_status === 4
                        ? "Refund"
                        : "Not Set"}
                    </Label>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 text-md-end">
                <div className="row mb-1">
                  <div className="col-12 text-start">
                    <span style={{ fontWeight: 600 }}>Name: </span>
                    {capitalizeFirstLetter(obj.student.name)}
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-12 text-start">
                    <span style={{ fontWeight: 600 }}>Father Name: </span>
                    {capitalizeFirstLetter(
                      obj.student.father_name === ""
                        ? " - - "
                        : obj.student.father_name
                    )}
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-12 text-start">
                    <span style={{ fontWeight: 600 }}>Contact Number: </span>
                    +92{obj.student.contact_number}
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-12 text-start">
                    <span style={{ fontWeight: 600 }}>Type: </span>
                    {obj.voucher_type == 1
                      ? "Registration"
                      : obj.voucher_type == 2
                      ? "Fee Voucher"
                      : "Custom"}
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "-10px" }}>
                <hr />
              </div>

              {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className=" ">
                  <span style={{ fontWeight: 600 }}>Invoice Number:</span>{" "}
                  {obj._id}
                  <br />
                  <span style={{ fontWeight: 600 }}>Date:</span>{" "}
                  {formatDateWithOutDay(obj.due_date)}
                  <br />
                  <span style={{ fontWeight: 600 }}>Batch:</span>{" "}
                  {obj.batch.name}
                  <br />
                </div>
                <div></div>
                <div className=" ">
                  <span style={{ fontWeight: 600 }}>Name:</span>{" "}
                  {capitalizeFirstLetter(obj.student.name)}
                  <br />
                  <span style={{ fontWeight: 600 }}>Father Name:</span>{" "}
                  {capitalizeFirstLetter(obj.student.father_name)}
                  <br />
                </div>
              </div> */}
            </div>
          </div>

          <div className="container">
            <table
              style={{ borderCollapse: "collapse", width: "100%" }}
              id="table"
            >
              <thead
                style={{
                  // backgroundColor: "#000033",
                  backgroundColor: "#e5e4e2",
                  // color: "#fff",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <tr>
                  <th>Fee Title</th>
                  <th>Fee</th>
                  {obj.voucher_type == 2 ? <th>Fee Discount</th> : ""}
                  <th>Additional Discount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody
                style={{
                  // backgroundColor: "#000033",
                  // color: "#fff",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <tr>
                  <td>{capitalizeFirstLetter(obj.fee_title)}</td>
                  <td>{obj.fee}</td>
                  {obj.voucher_type == 2 ? <td>{obj.fee_discount}</td> : ""}
                  <td>{obj.additional_discount}</td>
                  <td>{obj.fee - calculateDiscountAmount(obj)}</td>
                </tr>
              </tbody>
            </table>

            <div className="row">
              <div className="col-12 col-sm-5 col-md-7 col-xl-8"></div>
              <div className="col-12 col-sm-7 col-md-5 col-xl-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <span style={{ fontWeight: "600" }} id="paid-to-date1">
                    Paid Amount:
                  </span>
                  <span
                    style={{ textAlign: "right", fontWeight: "600" }}
                    id="paid-to-date2"
                  >
                    {calculatePaidAmount(obj)} Rs
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-5 col-md-7 col-xl-8"></div>
              <div className="col-12 col-sm-7 col-md-5 col-xl-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1px",
                  }}
                >
                  <span style={{ fontWeight: "600" }} id="paid-to-date1">
                    Pending Amount:
                  </span>
                  <span
                    style={{ textAlign: "right", fontWeight: "600" }}
                    id="paid-to-date2"
                  >
                    {calculatePendingAmount(obj)} Rs
                  </span>
                </div>
              </div>
            </div>

            {/* <div style={{ textAlign: "center", fontWeight: "600", marginTop: "20px" }} id="paid-to-date">Paid Amount: {calculatePaidAmount(obj)} Rs</div>
      <div style={{ textAlign: "center", fontWeight: "600", marginTop: "1px" }} id="due">Pending Amount: {calculatePendingAmount(obj)} Rs</div> */}
          </div>
        </div>

        <div style={{ textAlign: "center", marginTop: "100px" }} id="footer">
          &copy; {currentYear} DDIT
        </div>
      </div>
    </div>
  );
};

export default VoucherInvoiceDetail;
