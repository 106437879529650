import PropTypes from "prop-types";
// material
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Grid,
  Badge,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import CircularProgressWithLabel from "src/components/loaders/CircularProgressWithLabel";
import {
  formatDateWithDay,
  formatDateWithOutDay,
  formatTime24Hours,
} from "src/utils/formatTime";
import { capitalizeFirstLetter } from "src/utils/formatText";
import React, { useRef } from "react";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { s3baseUrl } from "src/config/config";
import { useState } from "react";
import { Iconify, Label } from "src/components";
import CustomCard from "src/components/GeneralComponents/CustomCard";
import DataNotFoundAll from "src/components/GeneralComponents/DataNotFoundAll";
import DataNotFoundCustomMessage from "src/components/GeneralComponents/DataNotFoundCustomMessage";
import VoucherInvoiceDetail from "src/pages/Vouchers/VoucherInvoiceDetail";
import CustomCardFullLengthVoucher from "src/components/GeneralComponents/CustomCardFullLengthVoucher";
import { calculatePaidAmount, calculatePendingAmount } from "src/utils/formatNumber";
import CustomCardFullLengthVoucherNewDesign from "src/components/GeneralComponents/CustomCardFullLengthVoucherNewDesign";

// ----------------------------------------------------------------------
 
export default function BatchDetailVouchersList({ vouchers }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

 
  const ModalStyle = {
    width: isSmallScreen ? "100%" : "70%",
    height: "90vh",
    minWidth: isSmallScreen ? "100%" : "70%",
    // maxHeight: "80vh", // Set a maximum height for the modal
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // p: 0,
    overflow: "hidden", // Hide vertical scroll
  };
  const getIcon = (name) => <Iconify icon={name} width={25} height={25} />;

  
  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRowData) {
    setOpenModal(true);
    setSelectedRow(selectedRowData);
  }

  const handleCardClick = (voucher) => {
    handleOpenModal(voucher);

    // event.preventDefault();
    console.log('Card clicked! Voucher:  __voucher',voucher);
    // You can perform any action you want
  };

  return (
    <>
      {vouchers.total_fee_vouchers.length > 0 ? (
      
          <Grid container spacing={1}>
            {vouchers.total_fee_vouchers.map((voucher, index) => {
              return (
                <div className="mb-2" key={index}>

             
                <CustomCardFullLengthVoucherNewDesign
                //  key={index}
                Number={index + 1}
                voucher={voucher}
                Fee={voucher.fee}
                Paid={calculatePaidAmount(voucher)}
                Pending={calculatePendingAmount(voucher)}
              
                // Fee={voucher.fee}
                Title={voucher.fee_title}
                voucherStatus={voucher.voucher_status}
                voucherType={voucher.voucher_type}
                dueDate={voucher.due_date}
                className="all-vouchers hover_effect_for_batch_card"
                handleCardClick={handleCardClick}
                // onClick={(event) => handleCardClick(event, voucher)}
                Icon={
                  voucher.voucher_status == 1
                    ? getIcon("mdi:cash")
                    : voucher.voucher_status == 2 || voucher.voucher_status == 4 
                    ? getIcon("mdi:cash-check")
                    : getIcon("mdi:cash-clock")
                }
              />
                  
                  </div>
              );
            })}
          </Grid>
      ) : (
        <Card>
          {/* <div className="my-5 py-5">
            <DataNotFoundAll />
          </div> */}
            <div className="my-5 py-5">
            <DataNotFoundCustomMessage 
            // Heading="Payment Status <span className='text-success'>OK!</span>" 
            Heading={<span dangerouslySetInnerHTML={{__html: "<span class='text-success'> Payment Status OK! </span>"}} />}
            SubHeading={`Your payments are up to date. No vouchers found.`}
            />
          </div>
        </Card> 
      )}
      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Detail"
        StyleFromHeader={ModalStyle}
        componentToPassDown={
          <> 
               <Box sx={{ 
      // width: 200,  // set the width as needed
      // height: 200, // set the height as needed
      overflow: 'auto', // enable scrolling
      // border: '1px solid #ccc', // add border for visualization
    }}
      >
   <Box sx={{ 
        //   borderBottom: 1, borderColor: 'divider',
        minWidth: 500,
      }}>
            <VoucherInvoiceDetail obj={selectedRow} />
 
 </Box> 
 </Box> 
          </>
        }
      />
    </>
  );
}
