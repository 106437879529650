import { invokeApi } from "src/utils";

export const _list_fee_voucher = async (data) => {

    const requestObj = {
      path: `api/fee_voucher/list_fee_voucher`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

export const _filter_voucher = async (data) => {

    const requestObj = {
      path: `api/fee_voucher/filter_voucher`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _create_custom_voucher= async (data) => {
    const requestObj = {
      path: `api/fee_voucher/create_custom_voucher`, 
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_fee_voucher= async (data) => {
    const requestObj = {
      path: `api/fee_voucher/add_fee_voucher`, 
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _detail_fee_voucher = async (id) => {
    const requestObj = {
      path: `api/fee_voucher/detail_fee_voucher/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };


  export const _edit_fee_voucher = async (id, data) => {
    const requestObj = {
      path: `api/fee_voucher/edit_fee_voucher/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _approve_fee_voucher = async (id, data) => {
    const requestObj = {
      path: `api/fee_voucher/approve_fee_voucher/` + id,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
// export const listBatch = async () => {
//   const requestObj = {
//     path: `api/batch/list_batch`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const listActiveBatch = async () => {
//   const requestObj = {
//     path: `api/batch/active_batch_list`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const delBatch = async (id) => {
//   const requestObj = {
//     path: `api/batch/delete_batch/` + id,
//     method: "DELETE",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const _list_freeze_batches = async (data) => {
//   const requestObj = {
//     path: `api/batch/list_freeze_batches`, 
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
 

// export const detailBatch = async (id) => {
//   const requestObj = {
//     path: `api/batch/detail_batch/` + id,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };
// export const _update_freeze_status = async (id) => {
//   const requestObj = {
//     path: `api/batch/update_freeze_status/` + id,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };
// export const editBatch = async (id, data) => {
//   const requestObj = {
//     path: `api/batch/edit_batch/` + id,
//     method: "PUT",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };


// export const _filter_batch = async (data) => {

//   const requestObj = {
//     path: `api/batch/batch_filter`,
//     method: "POST",
//     headers: {
//       // "Content-Type": "multipart/form-data",
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

// export const _move_batch_students = async (data) => {
//   const requestObj = {
//     path: `api/batch/move_batch_students`, 
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

// export const _move_student_from_batch = async (data) => {
//   const requestObj = {
//     path: `api/batch/move_student_from_batch`, 
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };