export function capitalizeFirstLetter(str) {
  if (str && str!==null && str!=="") {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
}

export function capitalizeAllLetters(str) {
  if (str && str!==null && str!=="") {
    return str.toUpperCase();
  } else {
    return str;
  }
}

export function capitalizeAllFirstLetters(str) {
  if (str && str!==null && str!=="") {
    // Split the string into words using spaces as the delimiter
    const words = str.split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
      return capitalizeFirstLetter(word);
    });

    // Join the words back together with spaces
    return capitalizedWords.join(" ");
  } else {
    return str;
  }
}


export function textEmptyToReturnHyphen(str) {
  if (str && str!==null && str!=="") {
    return str;
  } else {
    return " _ _ ";
  }
}

export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + " ... ";
  }
  return str;
}
export function htmlReturnFromCKEditor(str) {
  return (
    <div dangerouslySetInnerHTML={{ __html: str }} />
  );
}