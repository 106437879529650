import { filter } from "lodash";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  Grid,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import BatchListHead from "./components/BatchListHead";
import BatchListToolbar from "./components/BatchListToolbar";
import BatchMoreMenu from "./components/BatchMoreMenu";
import moment from "moment";

//

import { _student_batches, listBatch } from "src/DAL/batch";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterBatch from "./components/FilterBatch";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { useAppContext } from "src/hooks";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import {
  getStaticPrivilegesObj,
  privilegesTypeCheckAndShowOrNot,
} from "src/utils/FormatPrivileges";
import { formatTime24Hours } from "src/utils/formatTime";
import {
  LogoImg,
  simple_pattern_1,
  simple_pattern_10,
  simple_pattern_2,
  simple_pattern_3,
  simple_pattern_4,
  simple_pattern_5,
  simple_pattern_6,
  simple_pattern_8,
  simple_pattern_9,
} from "src/assets";
import { Box } from "@mui/system";
import DataNotFoundAll from "src/components/GeneralComponents/DataNotFoundAll";
import { useSnackbar } from "notistack";
import DataNotFoundCustomMessage from "src/components/GeneralComponents/DataNotFoundCustomMessage";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  // { id: "courses", label: "Courses", alignRight: false },
  // { id: "student_count", label: "Student Count", alignRight: false },
  { id: "student_count", label: "Student Count", alignRight: false },

  { id: "total_seats", label: "Total Seats", alignRight: false },
  { id: "avaliable_seats", label: "Available Seats", alignRight: false },

  { id: "time", label: "Class Time", alignRight: false },
  { id: "start_date_batch", label: "Start Date", alignRight: false },
  { id: "end_date_batch", label: "End Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "batch_status", label: "Batch Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {

  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      //  || _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BatchesList() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const myElementRef = useRef(null);
  function handleBatchesUpdate(updatedBatches) {
    setBatches(updatedBatches);
  }

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = batches.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(batches.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }

    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - batches.length) : 0;

  const filteredBatches = applySortFilter(
    batches,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredBatches.length === 0;

  const handleNav = () => {
    navigate("/batches/add-batch");
  };

  const handleDetailBatche = (row) => {
    navigate(`/batches/detail-batch/${row.id}`);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime, endTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    const endMoment = moment(endTime, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedEndTime = endMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime} to ${formattedEndTime}`;

    return formattedTime;
  }

  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await _student_batches();
    console.log("response listBatch _test _student_batches", response);
    if (response.code === 200) {
      const batches = response.student_batches.map((batch, index) => ({
        ...batch,
        id: batch._id,
        name: batch.name,
        // available_seats: batch.available_seats,
        start_date_batch: formatDate(batch.start_date_batch),
        end_date_batch: formatDate(batch.end_date_batch),
        time: formatTime(batch.start_time_class, batch.end_time_class),

        // available_seats: batch.available_seats <= 0 ? 0 : batch.available_seats,
        // available_seats : batch.available_seats <= 0 ? 0 : batch.available_seats,

        available_seats: batch.avaliable_seats <= 0 ? 0 : batch.avaliable_seats,

        total_seats: batch.total_seats,
        total_male_students: batch.total_male_students,
        total_female_students: batch.total_female_students,

        student_count: batch.student_count,
        status: batch.status,
        courses: batch.course ? batch.course : "",
        batch_status: batch.batch_status ? batch.batch_status : 0,
        student_count: batch.student_count ? batch.student_count : 0,
        number: index + 1,
      }));
      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const handleReset = () => {
    // setSearchText("");
    // SearchStudents("");
    // _set_user_privilege_access(getStaticPrivilegesObj);
    fetchBatches();
    setFilterName("");
    // Reset any search results or filters
    console.log("Reset");
  };

  useEffect(() => {
    fetchBatches();
    console.log("get_user_profile ____ABC", get_user_profile);
    console.log("get_user_privilege_access ____ABC", get_user_privilege_access);

    // setIsLoading(false);
  }, []);

  if (isLoading) {
    return <CircularLoader size="3rem" color="secondary" />;
  }

  return (
    <>
      <Page title="Batch">
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: "30px", marginBottom: "15px" }}
        >
          {/* container start */}
          <div className="container">
            <div
              className="d-flex"
              style={{
                flexDirection: "row",
                alignContent: "flex-end",
                justifyContent: "space-between",
                // marginBottom: "-15px",
              }}
            >
              {/* <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton> */}

              {/* <Typography variant="h4">Vouchers</Typography> */}
              <h4>Batches</h4>

              <div></div>
            </div>

            {/* <hr /> */}

            <Box sx={{ width: "100%" }}>
              {batches.length > 0 ? (
                <div className="row">
                  {batches.map((row) => {
                    const {
                      id,
                      name,
                      student_count,
                      courses,
                      course,
                      status,
                      start_date_batch,
                      end_date_batch,
                      time,
                      number,
                      total_male_students,
                      total_female_students,
                      total_seats,
                      available_seats,
                      batch_status,
                    } = row;
                    //   const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <div className="col-sm-12 col-lg-6 col-xl-4 d-flex">
                        <div className="card invoices-grid-card w-100 hover_effect_for_batch_card">
                          <RouterLink
                            to={`/batches/batch-detail/${row.id}`}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                            // className="on_click_text" // Add the class name you want here
                          >
                            <div className="card-header d-flex justify-content-between align-items-center">
                              {/* <a href="" className="invoice-grid-link">{course.title}</a> */}
                              <span className="invoice-grid-link">
                                <div className="views-lesson">
                                  <div className="batch_info_heading">
                                    Course
                                  </div>
                                  <div className="batch_info_sub_text">
                                    {capitalizeFirstLetter(course.title)}
                                  </div>
                                </div>

                                {/* {capitalizeFirstLetter(course.title)} */}
                              </span>
                              {/* <div className="dropdown dropdown-action">
                                    <a href="https://preschool.dreamstechnologies.com/template/invoice-grid.html#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" href="https://preschool.dreamstechnologies.com/template/edit-invoice.html"><i className="far fa-edit me-2"></i>Edit</a>
                                        <a className="dropdown-item" href="https://preschool.dreamstechnologies.com/template/view-invoice.html"><i className="far fa-eye me-2"></i>View</a>
                                        <a className="dropdown-item" href="javascript:void(0);"><i className="far fa-trash-alt me-2"></i>Delete</a>
                                    </div>
                                </div> */}
                            </div>
                            <div
                              className="card-middle"
                              style={{
                                backgroundImage: `url(${simple_pattern_4})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                color: "#fff",
                                // Additional styling properties as needed
                              }}
                            >
                              <h2
                                className="card-middle-avatar"
                                style={{
                                  // textShadow:
                                  //   "0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 40px white, 0 0 50px white, 0 0 60px white, 0 0 70px white, 0 0 80px white, 0 0 90px white, 0 0 100px black",
                                  fontWeight: "600",
                                }}
                              >
                                {capitalizeFirstLetter(name)}
                              </h2>
                            </div>
                            <div className="card-body">
                              <div className="row align-items-center">
                                <div className="col">
                                  <span>
                                    <i className="far fa-money-bill-alt"></i>{" "}
                                    Start Class{" "}
                                  </span>
                                  {/* <h6 className="mb-0">$1,54,220</h6> */}
                                  <h6 className="mb-0">
                                    {formatTime24Hours(row.start_time_class)}
                                  </h6>
                                </div>
                                <div className="col-auto">
                                  <span>
                                    <i className="far fa-calendar-alt"></i> End
                                    Class{" "}
                                  </span>
                                  {/* <h6 className="mb-0">23 Mar, 2022</h6> */}
                                  <h6 className="mb-0">
                                    {formatTime24Hours(row.end_time_class)}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div
                              className="card-footer"
                              style={{
                                backgroundImage: `url(${simple_pattern_5})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                // color: "#fff",
                                // Additional styling properties as needed
                              }}
                            >
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  {/* <span className="badge bg-primary-dark">
                                View
                              </span> */}

                                  <div className="views-lesson">
                                    <div className="batch_info_heading">
                                      Status
                                    </div>
                                    <div className="batch_info_sub_text">
                                      <Label
                                        variant="outlined"
                                        color={
                                          (row.batch_status === 1 &&
                                            "warning") ||
                                          (row.batch_status === 4 &&
                                            "success") ||
                                          (row.batch_status === 5 && "info") ||
                                          "info"
                                        }
                                      >
                                        {row.batch_status === 1
                                          ? "Pending"
                                          : row.batch_status === 2
                                          ? "Confirm"
                                          : row.batch_status === 3
                                          ? "Started"
                                          : row.batch_status === 4
                                          ? "Completed"
                                          : row.batch_status === 5
                                          ? "Freeze"
                                          : "Not Set"}
                                      </Label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </RouterLink>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Card>
                  {/* <div className="my-5 py-5">
                  <DataNotFoundAll />
                </div> */}

                  <div className="my-5 py-5">
                    <DataNotFoundCustomMessage
                      // Heading="Batch Not Found"
                      Heading={
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              "<span class='text-primary'> Batch Not Found! </span>",
                          }}
                        />
                      }
                      SubHeading={`No batch found for the specified criteria. Please check the schedule for available batches.`}
                    />
                  </div>
                </Card>
              )}
            </Box>
          </div>
        </Grid>

        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenEditDrawer}
          onCloseDrawer={handleCloseEditDrawer}
          pageTitle="Filters"
          componentToPassDown={
            // <h1>hello</h1>
            <FilterBatch
              onCloseDrawer={handleCloseEditDrawer}
              isOpenDrawer={drawerState}
              batches={batches}
              setBatches={setBatches}
            />
          }
        />

        <CustomModal
          isOpenModal={openModal}
          handleCloseModal={handleCloseModal}
          pageTitle="Detail"
          componentToPassDown={
            //   <BatchAllDetail studentID={selectedRowStudent.id} />
            <>hi</>
          }
        />
      </Page>
    </>
  );
}
