import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Container,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Iconify } from "src/components";
import { CircularLoader, Page } from "src/components";
import { capitalizeFirstLetter } from "src/utils/formatText";
import {
  _detail_leave_request_for_admin,
  _detail_leave_request_for_student,
  _update_leave_request_for_admin,
  _update_leave_request_for_student,
} from "src/DAL/leave_requests";
import { listStudent } from "src/DAL/student";
import { _student_batches } from "src/DAL/batch";

const EditLeaveRequest = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [batches, setBatches] = useState([]);
  const [formInputs, setFormInputs] = useState({
    student_id: "",
    leave_reason: "",
    leave_date: dayjs(),
    batch_id: [], // Initialize as an empty array
  });
  const [startDate, setStartDate] = useState(dayjs());

  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange2 = (e) => {
    const { target } = e;
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
    });
  };

  const handleChange3 = (e) => {
    const { target } = e;
    console.log("target __test", target);
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      batch_student_id: [],
      batch_fee_id: "",
    });
 
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setFormInputs((values) => ({
      ...values,
      leave_date: date,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    if (!startDate) {
      error = "Leave Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    const formattedStartDate = startDate.format("YYYY-MM-DD");

    let postData = {
      // student_id: formInputs.student_id,
      batches: formInputs.batch_id,
      leave_date: formattedStartDate,
      leave_reason: formInputs.leave_reason,
    };

    setIsLoading(true);
    const result = await _update_leave_request_for_student(id, postData);

    if (result.code === 200) {
      setIsLoading(false);
      navigate("/leave-requests");
      enqueueSnackbar("Updated Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchDetailLeaveRequest = async () => {
    setIsLoading(true);
    const response = await _detail_leave_request_for_student(id);
console.log("response _detail_leave_request_for_student",response);
    if (response.code === 200) {
      const leaveDate = response.leave_request.leave_date;
      const defaultStartDate = leaveDate ? dayjs(leaveDate) : dayjs();
      const batchIds = response.leave_request.batches.map(batch => batch._id);

      setFormInputs({
        
        ...response.leave_request,
        leave_date: defaultStartDate,
        student_id: response.leave_request.student._id,
        leave_reason: capitalizeFirstLetter(
          response.leave_request.leave_reason
        ),
        batch_id: batchIds, // Initialize as an empty array
      });

      setStartDate(defaultStartDate);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await listStudent();

    if (response.code === 200) {
      const students = response.students
        .filter((student) => student.status === 1)
        .map((student, index) => ({
          ...student,
          id: student._id,
          number: index + 1,
        }));

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  
  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await _student_batches();
    console.log("response _student_batches _student_batches _test", response);
    if (response.code === 200) {
      const batches = response.student_batches
        .filter((batch) => batch.status === true)
        .map((batch) => ({
          ...batch,
          id: batch._id,
          name: batch.name,
        }));

      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  useEffect(() => {
    fetchStudents();
    fetchDetailLeaveRequest();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Leave Request">
      {/* <div className="container col-10 mb-5"> */}
      <Container maxWidth="xl">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={() => navigate(-1)} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>

        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
           <h4> Edit Leave Request</h4>
          {/* <Typography variant="h4" sx={{ flex: 1 }}>
            Edit Leave Request
          </Typography> */}
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={2}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                  fullWidth
                  label="Batch"
                  select
                  value={formInputs.batch_id}
                  onChange={handleChange3}
                  name="batch_id"
                  SelectProps={{
                    multiple: true,
                  }}
                  required
                >
                  {batches.map((batch) => (
                    <MenuItem key={batch.id} value={batch.id}>
                      {capitalizeFirstLetter(batch.name)}
                    </MenuItem>
                  ))}
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={startDate}
                    onChange={handleDateChange}
                    label="Leave Date *"
                    format="MM-DD-YYYY"
                    minDate={dayjs()}
                    sx={{
                      width: "100%",
                    }}
                  />
                </LocalizationProvider>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Leave Reason"
                  value={formInputs.leave_reason}
                  onChange={handleChange2}
                  name="leave_reason"
                  required
                />
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Container>
    </Page>
  );
};

export default EditLeaveRequest;
