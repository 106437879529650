import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Icon,
  Card,
  Badge,
} from "@mui/material";
//
import Iconify from "./Iconify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { _get_user_roles } from "src/DAL/role";
import { useAppContext } from "src/hooks";
import {
  getStaticPrivilegesObj,
  privilegesNavSectionNameCheck,
  privilegesNavSectionNameCheckAsChildOptions,
} from "src/utils/FormatPrivileges";
import { _detail_customer_without_token } from "src/DAL/customer";

// ----------------------------------------------------------------------
const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  // ...theme.typography.body2,
  // height: 48,
  // position: "relative",
  // textTransform: "capitalize",
  // paddingLeft: theme.spacing(5),
  // paddingRight: theme.spacing(2.5),
  // color: theme.palette.text.secondary,

  // ":hover": {
  //   backgroundColor:  "red",
  //   borderRadius:"20px",
  // },

  // "&:before": {
  //   top: 0,
  //   right: 0,
  //   width: 3,
  //   bottom: 0,
  //   content: "''",
  //   display: "none",
  //   position: "absolute",
  //   borderTopLeftRadius: 4,
  //   borderBottomLeftRadius: 4,
  //   backgroundColor: theme.palette.primary.main,
  // },
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,

  // Default styles (without hover)
  "-webkit-tap-highlight-color": "transparent",
  backgroundColor: "transparent",
  outline: 0,
  border: 0,
  padding: "8px 0 8px 8px",
  cursor: "pointer",
  userSelect: "none",
  verticalAlign: "middle",
  appearance: "none",
  display: "flex",
  flexGrow: 1,
  justifyContent: "flex-start",
  alignItems: "center",
  textDecoration: "none",
  minWidth: 0,
  boxSizing: "border-box",
  textAlign: "left",
  transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  width: "90%",
  margin: "4px auto",
  fontFamily: "Montserrat, sans-serif",
  fontWeight: 400,
  fontSize: "0.85rem",
  lineHeight: 1.43,
  height: 50,
  position: "relative",
  borderRadius: 10,

  // Hover styles
  ":hover": {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    // width: "95%",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 20,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0px 4px",
    paddingTop:"2px",
    zIndex: 0,
  },
}));

function NavItem({
  item,
  active,
  supportCount,
  setSupportCount,
  announcementCount,
  setAnnouncementCount,
}) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const {
    _set_user_privilege_access,
    _get_user_profile,
    _get_user_privilege_access,
    _set_user_profile,
  } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleMenuItemClick = (title) => {
    // Add your custom logic here based on the clicked menu item's title.
    console.log(`Clicked on the '${title}' menu item.`);
    fetchUserRoles();
  };

  const fetchUserRoles = async () => {
    // setIsLoading(true);
    const response = await _get_user_roles();
    console.log("response fetchUserRoles _test _get_user_roles", response);
    if (response.code === 200) {
      console.log("get_user_profile _zzz", get_user_profile);
      console.log("response.privileges _zzz", response.privileges);
      setSupportCount(response?.waiting_support_ticket_count);
      setAnnouncementCount(response?.unread_announcement_count);
      // user.role.privilege
      const user = {
        ...get_user_profile,
        privilege: response.privileges,
      };

      console.log("JSON.stringify(user)", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));

      _set_user_profile({
        ...get_user_profile,
        privilege: response.privileges,
      });

      _set_user_privilege_access(response.privileges);
      console.log("response.privileges _get_user_roles", response.privileges);

      // const batches = response.batch.map((batch, index) => ({
      //   id: batch._id,
      //   name: batch.name,
      //   number: index + 1,
      // }));
      // setBatches(batches);
      // setIsLoading(false);
    } else {
      // enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  // const activeRootStyle = {
  //   color: "primary.main",
  //   fontWeight: "fontWeightMedium",
  //   bgcolor: alpha(
  //     theme.palette.primary.main,
  //     theme.palette.action.selectedOpacity
  //   ),
  //   "&:before": { display: "block" },
  // };
  const activeRootStyle = {
    color: "#ffffff",
    fontWeight: "fontWeightMedium",
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",

    "&:before": { display: "block" },
  };
  const activeIconStyle = {
    color: theme.palette.primary.main,
    // fontWeight: "fontWeightMedium",
    // backgroundColor: theme.palette.primary.main,
    // boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",

    // "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };
  const location = useLocation();
  // console.log(open, "locationlocationlocationlocation");
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
          // onClick={() => console.log('It works 1')}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          {/* <Box
            component={Icon}
            icon={open ? ArrowDownwardIcon : ArrowUpwardIcon}
            sx={{ width: 16, height: 16, ml: 1 }}
          /> */}
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              if (
                privilegesNavSectionNameCheckAsChildOptions(
                  item,
                  get_user_profile,
                  get_user_privilege_access
                ) === true
              ) {
                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                    // onClick={() => console.log('It works 2')}
                    onClick={() => handleMenuItemClick("It works 2")}
                  >
                    <ListItemIconStyle>
                      {item.icon ? (
                        icon
                      ) : (
                        <Box
                          component="span"
                          sx={{
                            width: 4,
                            height: 4,
                            display: "flex",
                            borderRadius: "50%",
                            alignItems: "center",
                            justifyContent: "center",
                            bgcolor: "text.disabled",
                            transition: (theme) =>
                              theme.transitions.create("transform"),
                            ...(isActiveSub && {
                              transform: "scale(2)",
                              bgcolor: "primary.main",
                            }),
                          }}
                        />
                      )}
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              } else {
                return null; // Return null for items you want to skip
              }
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
      // onClick={() => console.log('It works 3')}
      onClick={() => handleMenuItemClick("It works 3")}
    >
      <Card
        sx={{
          ...(isActiveRoot && activeIconStyle),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "7px",
          margin: "0px 0px",
          borderRadius: "10px",
        }}
      >
        {icon && icon}
        {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
      </Card>

      {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
      <ListItemText
        sx={{ marginLeft: "10px" }}
        disableTypography
        primary={title}
      />
      {info && info}
      <StyledBadge
        badgeContent={
          title === "Support Tickets"
            ? supportCount
            : title === "Announcements"
            ? announcementCount
            : 0
        }
        max={99}
        color="secondary"
      ></StyledBadge>
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({
  supportCount,
  setSupportCount,
  announcementCount,
  setAnnouncementCount,
  navConfig,
  ...other
}) {
  const { pathname } = useLocation();

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  // let get_user_privilege_access = _get_user_privilege_access();
  let get_user_privilege_access = {};

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  // async function fetchCustomerDetail() {
  //   if(get_user_profile){
  //     if(get_user_profile.type===3){
  //       const response = await _detail_customer_without_token();
  //       console.log('response _detail_customer_without_token',response);
  //       if (response.code === 200) {
  //         get_user_privilege_access = response.customer.role.privilege;
  //         console.log('response response.customer.role.privilege _detail_customer_without_token',response.customer.role.privilege);
  //         setIsLoading(false);
  //       } else {
  //         setIsLoading(false);
  //         // enqueueSnackbar(response.message, { variant: "error" });
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   console.log('response get_user_profile _detail_customer_without_token',get_user_profile);
  //   // fetchCustomerDetail();
  // }, [get_user_privilege_access]);

  return (
    <Box {...other}>
      <List
        disablePadding
        // sx={{ p: 1, pt: 0.4 }}
      >
        {navConfig.map((item) => {
          return (
            <NavItem
              key={item.title}
              item={item}
              active={match}
              onClick={() => console.log("It works")}
              supportCount={supportCount}
              setSupportCount={setSupportCount}
              announcementCount={announcementCount}
              setAnnouncementCount={setAnnouncementCount}
            />
          );
        })}
      </List>
    </Box>
  );
}
