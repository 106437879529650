import { invokeApi } from "src/utils";

export const _upload_assignment_from_student = async (id, data) => {
    const requestObj = {
      path: `api/assignment/upload_assignment/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  
  export const _detail_student_assignment = async (id) => {
    const requestObj = {
      // path: `api/collect_fee/detail_collect_fee/${id}`,
      path: `api/assignment/detail_student_assignment/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };